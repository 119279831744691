
import BreakpointMixin from "@/mixins/BreakpointMixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Nullable } from "../@types/BaseTypes";
import { PageInfo } from "../@types/Page";

@Component
export default class BaseViewManager extends Mixins(BreakpointMixin) {
  private prevPageCount = 10;

  @Prop({ type: Boolean, default: true })
  private paginatable!: boolean;

  @Prop({ type: Number, default: 0 })
  private totalItems!: number;

  @Prop({ type: Number, default: 0 })
  private limit!: number;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: Object, default: null })
  private pageInfo!: Nullable<PageInfo>;

  @Prop({ type: Array, default: () => [10, 20, 30] })
  private itemCount!: number[];

  private cursorStack: string[] = [];

  private get hasPreviousPage() {
    return (
      !!this.cursorStack.length ||
      (!!this.pageInfo && this.pageInfo.hasPreviousPage)
    );
  }

  private get hasNextPage() {
    return !!this.pageInfo && this.pageInfo.hasNextPage;
  }

  private get paginatedRecords() {
    const stackCount = (this.cursorStack.length + 1) * this.limit;

    return stackCount > this.totalItems ? this.totalItems : stackCount;
  }

  private get pageDisplay() {
    return `${this.prevPageCount} - ${this.paginatedRecords} of ${this.totalItems}`;
  }

  @Watch("limit")
  private limitObserver() {
    this.resetFirstPage();
  }

  @Watch("pageInfo")
  private pageInfoObserver(pageInfo: Nullable<PageInfo>) {
    if (!pageInfo?.endCursor && !pageInfo?.startCursor) {
      this.resetFirstPage();
    }
  }

  @Watch("$route", { deep: true, immediate: true })
  private routeObserver() {
    this.resetFirstPage();
  }

  private onPreviousPage() {
    this.cursorStack.pop();
    this.prevPageCount -= this.limit;

    this.$emit("page:prev", this.cursorStack[this.cursorStack.length - 1]);
  }

  private onNextPage() {
    const endCursor = this.pageInfo?.endCursor as string;
    this.cursorStack.push(endCursor);
    this.prevPageCount += this.limit;
    // this.prevPageCount = this.prevPageCount > this.totalItems ? this.totalItems : this.prevPageCount;

    this.$emit("page:next", endCursor);
  }

  private resetFirstPage() {
    this.cursorStack = [];
    this.prevPageCount = 1;
    this.$emit("page:next", null);
  }
}
