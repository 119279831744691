import gql from "graphql-tag";
import { Order } from "../fragments/order";

export const orderStatusUpdate = gql`
  mutation orderStatusUpdate($input: OrderStatusUpdateInput!) {
    orderStatusUpdate(input: $input) {
      orderStatus {
        ...OrderStatus
      }
    }
  }
  ${Order.fragments.orderStatus}
`;

export const refundOrder = gql`
  mutation refundOrder($input: RefundInput!) {
    refundOrder(input: $input) {
      order {
        ...OrderBasic
      }
    }
  }
  ${Order.fragments.orderBasic}
`;
