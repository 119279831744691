
// Core
import { Component, Mixins } from "vue-property-decorator";
// Extends
import ExtendedPromptMixin from "@/mixins/ExtendedPromptMixin";
// Children
import CopilotNotes from "@/components/GenAI/CopilotNotes.vue";
import PromptCard from "@/components/GenAI/PromptCard.vue";
// Models / Helpers
// Queries / Mutations

@Component({
  components: {
    CopilotNotes,
    PromptCard,
  },
})
export default class AutoreplyPrompt extends Mixins(ExtendedPromptMixin) {}
