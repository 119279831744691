
import { Nullable } from "@/@types/BaseTypes";
import { OrderImpl } from "@/@types/implementations/OrderImpl";
import { Vue, Component, Prop } from "vue-property-decorator";
import { startCase } from "lodash-core";
import { OrderStatuses, PaymentStatuses } from "@/@types/enums/Order";

@Component
export default class OrderDetailsDelivery extends Vue {
  @Prop({ type: Object, default: null })
  private orderDetails!: Nullable<OrderImpl>;

  private startCase(str: string) {
    return startCase(str);
  }

  private paymentStatusColors = [
    { status: PaymentStatuses.payPending, color: "orange--text" },
    { status: PaymentStatuses.paySuccess, color: "success--text" },
    { status: PaymentStatuses.forReupload, color: "orange--text darken-5" },
    { status: PaymentStatuses.forConfirmation, color: "primary--text" },
    { status: PaymentStatuses.refundSuccess, color: "success--text" },
  ];

  private statusColors = [
    { status: OrderStatuses.pending, color: "orange--text" },
    { status: OrderStatuses.cancelled, color: "red--text" },
    { status: OrderStatuses.forDelivery, color: "primary--text" },
    { status: OrderStatuses.completed, color: "success--text" },
    { status: OrderStatuses.refunded, color: "orange--text darken-5" },
  ];

  private get paymentStatusColorGenerator() {
    return this.paymentStatusColors.find(
      ({ status }: { status: string }) =>
        status === this.orderDetails?.orderStatus.paymentStatus
    )?.color;
  }

  private get orderStatusColorGenerator() {
    return this.statusColors.find(
      ({ status }: { status: string }) =>
        status === this.orderDetails?.orderStatus.status
    )?.color;
  }
}
