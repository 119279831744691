import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    component: () => import("../layouts/Wrapper.vue"),
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "Login" },
        component: () => import("../pages/Login.vue"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("../pages/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin-index",
    redirect: { name: "overview" },
    component: () => import("../layouts/Authenticated.vue"),
    children: [
      {
        path: "overview",
        name: "overview",
        meta: { title: "Overview" },
        component: () => import("../pages/Overview.vue"),
      },
      {
        path: "orders",
        name: "orders",
        meta: { title: "Orders" },
        redirect: { name: "orders-index" },
        component: () => import("../pages/Orders.vue"),
        children: [
          {
            path: "",
            name: "orders-index",
            meta: { title: "Orders" },
            component: () => import("../pages/OrdersIndex.vue"),
          },
        ],
      },
      {
        path: "merchants",
        name: "merchants",
        meta: { title: "Merchants" },
        redirect: { name: "merchants-index" },
        component: () => import("../pages/Merchants.vue"),
        children: [
          {
            path: "",
            name: "merchants-index",
            meta: { title: "Merchants" },
            component: () => import("../pages/MerchantsIndex.vue"),
          },
          {
            path: ":merchantUuid",
            meta: { title: "Merchants" },
            redirect: { name: "registration-details" },
            component: () => import("../pages/Merchant.vue"),
            children: [
              {
                path: "registration",
                name: "registration-details",
                meta: { title: "Merchants" },
                component: () =>
                  import("../pages/MerchantRegistrationDetails.vue"),
              },
              {
                path: "order-history",
                name: "merchant-order-history",
                meta: { title: "Merchants" },
                component: () => import("../pages/MerchantOrders.vue"),
              },
              {
                path: "billing-history",
                name: "merchant-billings",
                meta: { title: "Merchants" },
                component: () => import("../pages/MerchantBillings.vue"),
              },
              {
                path: "mini-app-payment-shipping",
                name: "merchant-settings",
                meta: { title: "Merchants" },
                component: () => import("../pages/MerchantSettings.vue"),
              },
              {
                path: "chatbot-ai-settings",
                name: "chatbot-ai-settings",
                meta: { title: "Merchants" },
                component: () => import("../pages/ChatbotAiSettings.vue"),
              },
              {
                path: "top-up",
                name: "merchant-top-up",
                meta: { title: "Merchants" },
                redirect: { name: "merchant-top-up-grab" },
                component: () => import("../pages/BasePage.vue"),
                children: [
                  {
                    path: "grab",
                    name: "merchant-top-up-grab",
                    meta: { title: "Merchants" },
                    component: () => import("../pages/MerchantTopUpGrab.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "sales-partners",
        name: "sales-partners",
        redirect: { name: "sales-partners-index" },
        meta: { title: "Sales Partner" },
        component: () => import("../pages/SalesPartners.vue"),
        children: [
          {
            path: "",
            name: "sales-partners-index",
            meta: { title: "Sales Partner" },
            component: () => import("../pages/SalesPartnersIndex.vue"),
          },
          {
            path: ":salesPartnerUuid",
            name: "sales-partner",
            redirect: { name: "sales-partner-billings" },
            meta: { title: "Sales Partner" },
            component: () => import("../pages/SalesPartner.vue"),
            children: [
              {
                path: "billings",
                name: "sales-partner-billings",
                meta: { title: "Sales Partner" },
                component: () => import("../pages/SalesPartnerBillings.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "jnt-accounts",
        name: "jnt-account",
        redirect: { name: "jnt-accounts" },
        meta: { title: "J&T Accounts" },
        component: () => import("../pages/JntAccount.vue"),
        children: [
          {
            path: "",
            name: "jnt-accounts",
            meta: { title: "J&T Accounts" },
            component: () => import("@/pages/JntAccounts.vue"),
          },
        ],
      },
      {
        path: "gen-ai",
        name: "gen-ai",
        redirect: { name: "gen-ai-index" },
        meta: { title: "Gen AI" },
        component: () => import("@/pages/GenAI/Base.vue"),
        children: [
          {
            path: "",
            name: "gen-ai-index",
            meta: { title: "Gen AI" },
            component: () => import("@/pages/GenAI/Index.vue"),
          },
        ],
      },
      {
        path: "announcements",
        name: "announcements",
        meta: { title: "Announcements" },
        component: () => import("../pages/ComingSoon.vue"),
      },
      {
        path: "sales-channel-requests",
        name: "sales-channel-requests",
        meta: { title: "Sales Channel Requests" },
        component: () => import("../pages/ComingSoon.vue"),
      },
      {
        path: "billing-manager",
        name: "billing-manager",
        meta: { title: "Billing Manager" },
        component: () => import("../pages/BillingManager/Base.vue"),
        children: [
          {
            path: "",
            name: "billing-manager",
            meta: { title: "Billing Manager" },
            component: () => import("../pages/BillingManager/Billings.vue"),
          },
          {
            path: ":billingUuid",
            name: "billing-details",
            meta: { title: "Billing Manager" },
            component: () =>
              import("../pages/BillingManager/BillingDetails.vue"),
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        meta: { title: "Settings" },
        component: () => import("../pages/ComingSoon.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../layouts/Wrapper.vue"),
    redirect: { name: "not-found-index" },
    children: [
      {
        path: "",
        name: "not-found-index",
        component: () => import("../pages/NotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
