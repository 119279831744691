import { Component, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import LocalizationMixin from "./LocalizationMixin";
import { TranslateResult } from "vue-i18n";

@Component
export default class FormMixin extends Mixins(
  validationMixin,
  LocalizationMixin
) {
  public resetForm() {
    (this.$refs.form as any).reset();
    this.resetValidations();
  }

  private resetValidations() {
    this.$v.$reset();
  }

  private get vm() {
    return this as any;
  }

  public fieldErrors(label: string, $v: any) {
    const errors: TranslateResult[] = [];

    if (!$v.$dirty) {
      return errors;
    }

    if (!$v.required) {
      const msg = this.vm.requiredFieldMessage(label);
      errors.push(msg);
    }

    if ($v.email != null && !$v.email) {
      const msg = this.vm.invalidFieldMessage(label);
      errors.push(msg);
      return errors;
    }

    if ($v.unique != null && !$v.unique) {
      const msg = this.vm.uniqueFieldMessage(label);
      errors.push(msg);
    }

    return errors;
  }
}
