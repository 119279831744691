import { startCase } from "lodash-core";
import { Nullable } from "@/@types/BaseTypes";
import { Cart, CartItem, PromoCode } from "@/@types/Cart";
import { Kyc, PageRecipient } from "@/@types/Customer";
import { SalesChannel } from "@/@types/enums/Order";
import { OrderImpl } from "@/@types/implementations/OrderImpl";
import { OrderDetails, OrderStatusFull } from "@/@types/Order";
import { PaymentOptionBasic } from "@/@types/Payment";
import { ShippingMethodFull } from "@/@types/Shipping";
import { fullDateTime } from "@/utils/helpers/date-formatter";
import { shortDateOne } from "@/utils/helpers/date-formatters";
import { currency } from "@/utils/helpers/numeric-formatter";

export class OrderModel implements OrderImpl {
  uuid!: string;
  transactionNumber!: string;
  firstName!: string;
  lastName!: string;
  grandTotal!: number;
  platformOrigin!: SalesChannel;
  advanceOrder!: boolean;
  advanceOrderDate!: Nullable<string>;
  bankSlipUrl!: Nullable<string>;
  buyoutAdditionalFee!: Nullable<number>;
  cart!: Cart;
  changeFor!: number;
  completedKycAt!: Nullable<string>;
  contactDialCode!: string;
  contactNumber!: string;
  courierDeliveryStatus!: string;
  courierDeliveryStatusToMessage!: string;
  courierOrderId!: Nullable<string>;
  courierRiderMobileNumber!: Nullable<string>;
  courierRiderName!: Nullable<string>;
  createdAt!: string;
  currency!: string;
  deliveryAddress!: string;
  deliveryAddressLine1!: string;
  deliveryAddressLine2!: string;
  deliveryBarangay!: string;
  deliveryBuilding!: Nullable<string>;
  deliveryFee!: number;
  deliveryFloor!: Nullable<string>;
  deliveryRoom!: Nullable<string>;
  email!: string;
  existingKycRefNo!: Nullable<string>;
  idCardType!: Nullable<string>;
  idCardUrl!: Nullable<string>;
  idNumber!: Nullable<string>;
  identityPhotoUrl!: Nullable<string>;
  items!: CartItem[];
  kycApprovedAt!: Nullable<string>;
  kycDetails!: Nullable<Kyc>;
  kycLevel!: Nullable<string>;
  landmark!: Nullable<string>;
  nearestLandmark!: Nullable<string>;
  orderStatus!: OrderStatusFull;
  pageRecipient!: PageRecipient;
  paymentOption!: PaymentOptionBasic;
  paymentProviderFee!: Nullable<number>;
  plateNumber!: Nullable<string>;
  promoCode!: Nullable<PromoCode>;
  proofOfBillingPhotoUrl!: Nullable<string>;
  proofOfBillingType!: Nullable<string>;
  proofOfIncomePhotoUrl!: Nullable<string>;
  proofOfIncomeType!: Nullable<string>;
  remarks!: Nullable<string>;
  requireMotobox!: boolean;
  requiredKyc!: boolean;
  shippingMethod!: ShippingMethodFull;
  subtotal!: number;
  tax!: number;
  thirdPartyPaymentReferenceId!: Nullable<string>;
  total!: number;
  transactionFee!: number;

  constructor(order: OrderDetails) {
    Object.assign(this, order);
  }

  public get pickupPoints() {
    return !this.shippingMethod ? [] : this.shippingMethod.pickupPoints;
  }

  public get displayedMobileNo() {
    return `${this.contactDialCode} ${this.contactNumber}`.replace(/ /g, "-");
  }

  public get displayedPlacedAt() {
    return shortDateOne(this.orderStatus.placedAt);
  }

  public get displayedCompletedAt() {
    return this.orderStatus.completedAt
      ? shortDateOne(this.orderStatus.completedAt)
      : "N/A";
  }

  public get displayedBuyoutAdditionalFee() {
    return currency(this.buyoutAdditionalFee || 0);
  }

  public get displayedChangeFor() {
    return currency(this.changeFor);
  }

  public get displayedTransactionFee() {
    return currency(this.transactionFee);
  }

  public get displayedPromoValue() {
    if (!this.promoCode) {
      return currency(0);
    }

    const { value, valueType } = this.promoCode;
    let promoValue = 0;
    if (valueType === "absolute") {
      promoValue = value > this.subtotal ? this.subtotal : value;
    } else if (valueType === "percentage") {
      promoValue = this.subtotal * (value / 100);
    }

    return currency(promoValue);
  }

  public get displayedPromoCode() {
    return this.promoCode ? this.promoCode.code : "N/A";
  }

  public get displayedSubtotal() {
    return currency(this.subtotal);
  }

  public get displayedGrandTotal() {
    return currency(this.grandTotal);
  }

  public get displayedShippingFee() {
    const shippingFee = this.shippingMethod ? this.deliveryFee : 0;
    return currency(shippingFee);
  }

  public get displayedShippingName() {
    return this.shippingMethod ? this.shippingMethod.name : "N/A";
  }

  public get displayedCustomerName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public get isForDelivery() {
    // TODO: use enum
    return this.orderStatus.status === "for_delivery";
  }

  public get isCancelled() {
    // TODO: use enum
    return this.orderStatus.status === "cancelled";
  }

  public get isCompleted() {
    // TODO: use enum
    return this.orderStatus.status === "completed";
  }

  public get displayedShipoutDate() {
    return fullDateTime(this.orderStatus.shipOutDate as string);
  }

  public get displayedEstimateArriveDate() {
    return fullDateTime(this.orderStatus.estimateArriveDate as string);
  }

  public get displayedCancelledAt() {
    return fullDateTime(this.orderStatus.cancelledAt as string);
  }

  public get salesChannel() {
    const platformOrigin =
      this.platformOrigin === SalesChannel.gcash
        ? "GLife"
        : startCase(this.platformOrigin);

    return platformOrigin;
  }
}
