
import { Mixins, Component, Prop } from "vue-property-decorator";
import { gracePeriodActivate } from "@/api/mutations/grace-period-activation";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
// import AppBasic from "@/plugins/graphql/queries/v2/app-basic.gql";

@Component
export default class GracePeriodActivation extends Mixins(
  BreakpointMixin,
  NotifierMixin,
  LoaderMixin
) {
  @Prop({ type: String, required: true })
  private uuid!: string;

  @Prop({ type: Boolean, default: false })
  private deactivationPage!: boolean;

  private get maxWidth(): string {
    if (this.deactivationPage) {
      return "unset";
    }

    return "199";
  }

  /**
   * Set Tooltip Left Offset Width for different pages
   * @author Peter John Alvarado <peterjohn@gorated.ph>
   * @since Version {0.15.0}
   *
   * @return {String}
   */
  private get offsetLeft(): string {
    if (this.deactivationPage) {
      return "100";
    }

    return "0";
  }

  private async onActivate() {
    this.loadingInProgress();

    await this.activate()
      .then(() => {
        this.showSuccessAlert("Grace Period Activated");
      })
      .catch((e) => {
        this.showErrorAlert(e.message);
      })
      .finally(() => {
        // this.loadingDone();
      });
  }

  private activate() {
    const vm = this as any;
    return this.$apollo.mutate({
      mutation: gracePeriodActivate,
      variables: {
        input: { billingUuid: vm.uuid },
      },
      update(data: any) {
        vm.$emit("extended");
      },
    });
  }
}
