export enum PaymentGatewayTypes {
  manualPayment = "manual_payment",
  provider = "provider",
  thirdParty = "credit_card_third_party_provider",
}

export enum PaymentTypes {
  bank = "bank",
  cod = "cod",
  thirdParty = "third_party",
  creditCard = "credit_card",
  provider = "provider",
}

export enum ThirdPartyProvider {
  dragonpay = "dragonpay",
  paymaya = "paymaya",
  gcash = "gcash",
  paymongo = "paymongo",
}

export enum CreditCardProvider {
  magpie = "magpie",
  acardpay = "acardpay",
  paymongo = "paymongo",
  stripe = "stripe",
  xendit = "xendit",
}
