
import { Nullable } from "@/@types/BaseTypes";
import { AdminOrderStatus } from "@/@types/Order";
import PaginatorMixin from "@/mixins/PaginatorMixin";
import { Mixins, Component, Prop } from "vue-property-decorator";
import { adminOrders as query } from "../api/queries/orders";
import { currency, numeral } from "../utils/helpers/numeric-formatter";

@Component({
  components: { OrderDetails: () => import("../components/OrderDetails.vue") },
  apollo: {
    adminOrders: {
      query,
      variables() {
        return { ...this.queryVariables };
      },
      // FOR OPTIMIZATION
      fetchPolicy: "network-only",
      update({ adminOrders }: any) {
        this.totalCount = adminOrders.totalCount;
        this.pageInfo = adminOrders.pageInfo;
        this.orderUuid = null;
        const orders = adminOrders.edges.map(
          ({ node }: { node: AdminOrderStatus }) => ({
            ...node,
            customerName: `${node.order.firstName} ${node.order.lastName}`,
            displayedAmount: currency(node.order.grandTotal),
          })
        );

        if (orders.length) {
          this.loadOrder(orders[0]);
        }

        return orders;
      },
    },
  },
})
export default class OrdersIndex extends Mixins(PaginatorMixin) {
  @Prop({ type: String, default: "pending" })
  private tab!: string;

  private filter = null;

  private keyword = "";

  private adminOrders: AdminOrderStatus[] = [];

  private orderUuid: Nullable<string> = null;

  private get humanizedTotalCount() {
    return numeral(this.totalCount || 0);
  }

  private get filterOptions() {
    return [
      { text: "Merchants", value: "merchant_name" },
      { text: "Payment Method", value: "payment method" },
      { text: "Shipping Method", value: "shipping_method" },
    ];
  }

  private get queryVariables() {
    return {
      status: this.tab,
      first: this.limit,
      last: null,
      keyword: this.keyword,
      after: this.startCursor,
    };
  }

  private get headers() {
    const commonProps = {
      align: "start",
      sortable: false,
      class: "font-weight-bold text-center text-uppercase grey lighten-4",
    };

    return [
      {
        ...commonProps,
        text: "Merchant ID",
        width: "150px",
        value: "pageId",
      },
      {
        ...commonProps,
        text: "Merchant Name",
        width: "300px",
        value: "appName",
      },
      {
        ...commonProps,
        text: "Reference Number",
        value: "order.transactionNumber",
        width: "200px",
      },
      {
        ...commonProps,
        text: "Product Name",
        value: "order.firstItem.productName",
        width: "300px",
      },
      {
        ...commonProps,
        text: "Customer Name",
        value: "customerName",
        width: "200px",
      },
      {
        ...commonProps,
        text: "Total Amount",
        value: "displayedAmount",
        width: "150px",
      },
      {
        ...commonProps,
        text: "Payment Method",
        value: "order.paymentOption.paymentName",
        width: "150px",
      },
      {
        ...commonProps,
        text: "Shipping Method",
        value: "order.shippingMethod.name",
        width: "150px",
      },
    ];
  }

  private onKeywordSearch(search: string) {
    this.keyword = search;
  }

  private itemClass({ order: { uuid } }: { order: { uuid: string } }) {
    const activeClass =
      uuid === this.orderUuid
        ? "grey lighten-4 font-weight-medium primary--text"
        : "";
    return `cursor-pointer ${activeClass}`;
  }

  private loadOrder({ order: { uuid } }: { order: { uuid: string } }) {
    this.orderUuid = uuid;
    const el = document.getElementById("order-details");
    if (el) {
      el.scrollTop = 0;
    }
  }
}
