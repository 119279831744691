
import { Nullable } from "@/@types/BaseTypes";
import FormMixin from "@/mixins/FormMixin";
import { required } from "vuelidate/lib/validators";
import { Component, Mixins, Prop } from "vue-property-decorator";
import LoaderMixin from "@/mixins/LoaderMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import { orderStatusUpdate } from "@/api/mutations/order";
import moment from "moment-mini";
import { FULL_DATE_TIME_FORMAT_TWO } from "@/utils/constants";

@Component({
  validations: {
    form: {
      estimateArriveDate: { required },
      shipOutDate: { required },
    },
  },
})
export default class ConfirmOrderDialog extends Mixins(
  FormMixin,
  LoaderMixin,
  NotifierMixin
) {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ type: String, default: null })
  private uuid!: Nullable<string>;

  private status = "for_delivery";

  private form = {
    additionalNotes: "",
    estimateArriveDate: moment().format(FULL_DATE_TIME_FORMAT_TWO),
    shipOutDate: moment().format(FULL_DATE_TIME_FORMAT_TWO),
  };

  private get input() {
    return {
      ...this.form,
      orderStatusUuid: this.uuid,
      status: this.status,
      estimateArriveDate: moment(this.form.estimateArriveDate).toISOString(),
      shipOutDate: moment(this.form.shipOutDate).toISOString(),
    };
  }

  private onCancel() {
    this.$emit("input", false);
    this.$v.$reset();
    // needed to bind default values in datetime picker in order to prevent
    // mutation errors
    this.form = {
      additionalNotes: "",
      estimateArriveDate: moment().format(FULL_DATE_TIME_FORMAT_TWO),
      shipOutDate: moment().format(FULL_DATE_TIME_FORMAT_TWO),
    };
  }

  private onConfirm() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();

      this.$apollo
        .mutate({
          mutation: orderStatusUpdate,
          variables: {
            input: this.input,
          },
        })
        .then(() => {
          this.loadingDone();
          this.onCancel();
          // For optimization: reuse apollo store here
          this.$router.push(
            "/admin/orders?tab=for_delivery&tabTitle=Confirmed"
          );
          window.scroll({ top: 0, left: 0 });
          const el = document.getElementById("order-details");
          if (el) {
            el.scrollTop = 0;
          }

          this.$emit(
            "notify:success",
            "Order confirmed and is scheduled for delivery"
          );
        })
        .catch((e: any) => {
          this.loadingDone();
          this.onCancel();
          this.$emit("notify:error", e.message);
        });
    }
  }
}
