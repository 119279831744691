// Core
import Vue from "vue";
import { Component } from "vue-property-decorator";
// Extends
// Children
// Models / Helpers
// Queries / Mutations

/**
 * Window size listener for more scoped reactivity
 * Only use if vueitfy breakpoints are not enough
 * @author Jan Canicon <jan@gorated.ph>
 * @since Version {x.x.x}
 */
@Component
export default class WindowSizeListenerMixin extends Vue {
  public window = {
    width: 0,
    height: 0,
  };

  private created() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
    window.addEventListener("resize", () => {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    });
  }
}
