
import { Vue, Component, Prop } from "vue-property-decorator";
import JsonCSV from "vue-json-csv";
import { Nullable } from "@/@types/BaseTypes";

@Component({
  components: {
    JsonCSV,
  },
})
export default class BaseCsvExporter extends Vue {
  private loading = false;

  @Prop({ type: Array, required: false })
  private csvData!: Nullable<[]>;

  @Prop({ type: Object, required: false })
  private csvLabels!: object;

  @Prop({ type: Array, required: false })
  private csvFields!: string[];

  @Prop({ type: String, required: false })
  private csvName!: string;

  private exportFinished(val: string) {
    this.$emit("exportFinished", val);
  }
}
