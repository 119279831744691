import gql from "graphql-tag";

const pickupPoint = gql`
  fragment PickupPoint on PickupPoint {
    uuid
    city
    country
    region
    regionCode
    street
    merchandiseType
    contactName
    contactNumber
    active
    shippingBuyout
    shippingNonBuyout
    remarks
    lat
    lng
    barangay
    building
    floorNumber
    unitNumber
  }
`;

const shippingBasic = gql`
  fragment ShippingMethodBasic on ShippingMethod {
    uuid
    name
    description
    price
    published
    customerInitiated
    shippingType
    thirdPartyProvider
  }
`;

const shippingMethod = gql`
  fragment ShippingMethod on ShippingMethod {
    uuid
    name
    description
    price
    published
    customerInitiated
    shippingType
    thirdPartyProvider
    pickupPoints {
      edges {
        node {
          ...PickupPoint
        }
      }
    }
  }
  ${pickupPoint}
`;

export const Shipping = {
  fragments: { shippingBasic, shippingMethod, pickupPoint },
};
