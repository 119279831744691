
import { Nullable } from "@/@types/BaseTypes";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BillingConfirmationDialog extends Vue {
  @Prop({ type: String, default: "" })
  private message!: string;

  @Prop({ type: String, default: null })
  private value!: Nullable<string>;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: String, required: true })
  private emitter!: string;

  @Prop({ type: String, default: "412" })
  private width!: string;

  @Prop({ type: String, default: "Confirmation" })
  private header!: string;

  @Prop({ type: String, default: "Confirm" })
  private confirmLabel!: string;

  private emitCancel() {
    if (
      this.emitter === "billing-approve" ||
      this.emitter === "billing-reject"
    ) {
      this.$emit("cancel");
    } else {
      this.$emit("input", null);
    }
  }
}
