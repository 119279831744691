
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class MerchantSettingShippingMethods extends Vue {
  @Prop({ type: Array, default: () => [] })
  private shippingMethods!: any;

  @Prop({ type: Boolean, default: false })
  private loading!: any;
}
