export const AUTH_TOKEN_KEY = "token";
export const HTTP_ENDPOINT =
  process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:3000/graphql";
export const WS_ENDPOINT =
  process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:3000/graphql-ws";
export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const FULL_DATE_TIME_FORMAT = "MMM DD, YYYY hh:mm a";
export const FULL_DATE_FORMAT = "MMM DD, YYYY";
export const FULL_DATE_TIME_FORMAT_TWO = "YYYY-MM-DD HH:mm";
export const TIME_FORMAT = "HH:mm";
export const CURRENCY_SYMBOL = "₱";
export const CURRENCY = "PHP";
// env key should be defined in order for API requests to work properly
export const JWT_HMAC_SECRET = process.env.VUE_APP_JWT_SECRET || "";
export const CAPTCHA_SITE_KEY = process.env.VUE_APP_CAPTCHA_KEY || "";
export const AES_SECRET_KEY = process.env.VUE_APP_AES_SECRET_KEY || "";
