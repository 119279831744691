// Will use ts here in order to utilize webpack tree-shaking capability. currently
// we don't have any way to import multiple queries in single .gql file
import gql from "graphql-tag";
import { Disbursement } from "../fragments/disbursement";
import { Connection } from "../fragments/connection";
import { Merchant } from "../fragments/merchant";
import { Order } from "../fragments/order";
import { Billing } from "../fragments/billing";
import { Payment } from "../fragments/payment";
import { Shipping } from "../fragments/shipping";

export const merchants = gql`
  query adminApps(
    $status: String = null
    $startDate: ISO8601DateTime = null
    $endDate: ISO8601DateTime = null
    $planType: AppPlanType = null
    $keywords: String
    $bundleName: String = null
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    adminApps(
      status: $status
      startDate: $startDate
      endDate: $endDate
      planType: $planType
      keywords: $keywords
      bundleName: $bundleName
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          ...MerchantBasic
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Merchant.fragments.merchantBasic}
  ${Connection.fragments.pageInfo}
`;

export const merchant = gql`
  query merchant($appUuid: ID!) {
    merchant: fetchAppByUuid(appUuid: $appUuid) {
      ...MerchantFull
    }
  }
  ${Merchant.fragments.merchantFull}
`;

export const disbursement = gql`
  query fetchAppByUuid($appUuid: ID!) {
    merchantDisbursement: fetchAppByUuid(appUuid: $appUuid) {
      uuid
      disbursement {
        ...DisbursementFull
      }
    }
  }
  ${Disbursement.fragments.disbursementFull}
`;

export const merchantOrderHistory = gql`
  query merchantOrderHistory(
    $appUuid: ID!
    $status: OrderStatuses
    $requiredKyc: Boolean
    $thirdParty: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
    $keyword: String
    $deliveryIds: [Int!]
    $distributorId: Int
    $distributor: Boolean
  ) {
    merchantOrderHistory: orderStatuses(
      appUuid: $appUuid
      status: $status
      requiredKyc: $requiredKyc
      thirdParty: $thirdParty
      first: $first
      last: $last
      after: $after
      before: $before
      keyword: $keyword
      deliveryIds: $deliveryIds
      distributorId: $distributorId
      distributor: $distributor
    ) {
      edges {
        node {
          ...OrderStatus
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${Connection.fragments.pageInfo}
  ${Order.fragments.orderStatus}
`;

export const merchantBillings = gql`
  query billings(
    $appUuid: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    billings(
      appUuid: $appUuid
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        node {
          ...BillingFull
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Billing.fragments.billingFull}
  ${Connection.fragments.pageInfo}
`;

export const merchantSettings = gql`
  query merchantSettings($appUuid: ID!) {
    paymentMethods: fetchAllActivePaymentGateways {
      edges {
        node {
          ...PaymentGateway
        }
      }
    }
    shippingMethods: fetchAllShippingMethod(appUuid: $appUuid) {
      edges {
        node {
          ...ShippingMethodBasic
        }
      }
    }
    accountHolder: fetchAccountHolder(appUuid: $appUuid) {
      uuid
      kycStatus
      capabilities
    }
  }
  ${Payment.fragments.paymentGateway}
  ${Shipping.fragments.shippingBasic}
`;

export const merchantGrabTopUps = gql`
  query grabTopUpList(
    $appUuid: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    grabTopUpList: grabExpressTopupList(
      appUuid: $appUuid
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        node {
          ...Credit
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Billing.fragments.credit}
  ${Connection.fragments.pageInfo}
`;

export const weeklyOrderSummaryNotification = gql`
  query ($appUuid: ID!) {
    fetchAppByUuid(appUuid: $appUuid) {
      weeklyOrderSummaryNotification
      mayaDeactivationScheduledDates
    }
  }
`;

export const mayaReminder = gql`
  query ($appUuid: ID!) {
    fetchAppByUuid(appUuid: $appUuid) {
      uuid
      mayaDeactivationScheduledDates
    }
  }
`;

export const merchantNames = gql`
  query adminApps($after: String, $before: String, $first: Int, $last: Int) {
    adminApps(after: $after, before: $before, first: $first, last: $last) {
      edges {
        node {
          uuid
          name
        }
      }
    }
  }
`;
