
import { Nullable } from "@/@types/BaseTypes";
import NotifierMixin from "@/mixins/NotifierMixin";
import { DocumentNode } from "graphql";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

@Component({
  apollo: {
    dataCount: {
      query() {
        return this.query;
      },
      variables() {
        return this.exportVariables;
      },
      update({ exportCount }: { exportCount: Number }) {
        return exportCount;
      },
    },
  },
  components: {
    ConfirmExportDialog: () => import("./ConfirmExportDialog.vue"),
  },
})
export default class BaseExportCard extends Mixins(NotifierMixin) {
  @Prop({ type: String, default: "Base Export" })
  private title!: string;

  @Prop({ type: Object, default: {} })
  private exportVariables!: object;

  @Prop({ type: Document })
  private mutation!: DocumentNode;

  @Prop({ type: Document })
  private query!: DocumentNode;

  @Prop({ type: Document })
  private subscription!: DocumentNode;

  @Prop({ type: Boolean, default: false })
  private incubation!: boolean;

  @Prop({ type: Boolean, default: false })
  private merchantProducts!: boolean;

  @Prop({ type: Boolean, default: false })
  private orderExports!: boolean;

  private dialogStatus: Nullable<string> = null;

  private dataCount: Number = 0;

  private loader = false;

  @Watch("loader")
  private subscribeOnClick() {
    if (!this.loader) {
      return;
    }

    this.$apollo.queries.dataCount.subscribeToMore({
      document: this.subscription,
      variables: {
        ...this.exportVariables,
      },
      updateQuery: () => {
        this.loader = false;
        this.showSuccessAlert("File Exported");
        this.$apollo.queries.dataCount.refetch();
        this.$apollo.queries.dataCount.sub.unsubscribe;
      },
    });
  }
}
