
import { Component, Mixins } from "vue-property-decorator";
import RadioBoxGroup from "@/components/RadioBoxGroup.vue";
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/FormMixin";
import ChatbotGlobalSettings from "@/api/queries/chatbot-global-settings.gql";
import { TranslateResult } from "vue-i18n";
import moment from "moment-mini";
import SaveGlobalChatbotSettings from "@/api/mutations/save-chatbot-global-settings.gql";
import SleepChatbot from "@/api/mutations/sleep-chatbot.gql";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import { Nullable } from "@/@types/BaseTypes";

@Component({
  components: {
    RadioBoxGroup,
  },
  apollo: {
    chatbotGlobalSetting: {
      query: ChatbotGlobalSettings,
      variables() {
        return {
          appUuid: this.$route.params.merchantUuid,
        };
      },
      update(data: any) {
        const settings = data.chatbotGlobalSettings;

        let selectedOperation = settings.operationMode;

        if (selectedOperation === "off") {
          selectedOperation = "Turn Off";
        }

        const selectedIndex = this.options.find(
          (option) => option.value.toLowerCase() === selectedOperation.toLowerCase()
        )?.index;

        this.selectedOption = selectedIndex;

        const { channels } = settings;

        const selectedChannels = this.channels.filter((channel) =>
          channels.includes(channel.value)
        );

        this.form.selectedChannels = selectedChannels;

        if (selectedOperation !== "off") {
          const delayStr = settings.delay;
          if (delayStr) {
            const delayArr = delayStr.split(" ");
            [this.form.waitTimeNumber, this.form.waitTimeLabel] = [
              Number(delayArr[0]),
              delayArr[1],
            ];
          } else {
            this.form.waitTimeNumber = 5;
            this.form.waitTimeLabel = "Minutes";
          }

          const sleepStr = settings.sleepDuration;

          if (sleepStr) {
            const sleepArr = sleepStr.split(" ");
            [this.form.sleepTimeNumber, this.form.sleepTimeLabel] = [
              Number(sleepArr[0]),
              sleepArr[1],
            ];
          } else {
            this.form.sleepTimeNumber = 1;
            this.form.sleepTimeLabel = "Hours";
          }

          this.form.applyTo = settings.sleepOption;
        }

        return data.chatbotGlobalSettings;
      },
      skip() {
        return !this.$route.params.merchantUuid;
      },
    },
  },
  validations() {
    const vm = this as any;
    const autopilot = () => vm.selectedOption === 3;
    const copilot = () => vm.selectedOption === 2;
    const validations = {
      form: {
        selectedChannels: {
          required: requiredIf(autopilot || copilot),
        },
        waitTimeNumber: {
          required: requiredIf(autopilot),
          invalidDelay(): boolean {
            const { waitTimeNumber } = (this as any).form;
            return vm.withinDelayValues(waitTimeNumber);
          },
        },
        waitTimeLabel: {
          required: requiredIf(autopilot),
        },
        sleepTimeNumber: {
          required: requiredIf(autopilot),
          invalidSleep(): boolean {
            const { sleepTimeNumber } = (this as any).form;
            return vm.withinSleepValues(sleepTimeNumber);
          },
        },
        sleepTimeLabel: {
          required: requiredIf(autopilot),
        },
        applyTo: {
          required: requiredIf(autopilot),
        },
      },
    };
    return validations;
  },
})
export default class ComponentName extends Mixins(
  FormMixin,
  BreakpointMixin,
  NotifierMixin
) {
  private options = [
    { value: "Turn Off", subText: "Turn off chatbot", index: 1 },
    {
      value: "Copilot",
      subText: "Helps you with recommended content",
      index: 2,
    },
    {
      value: "Autopilot",
      subText: "Will answer inquiry automatically",
      index: 3,
    },
  ];

  private chatbotGlobalSetting = null;

  private selectedOption = 2;

  private asleep = false;

  private saving = false;

  private showConfirmationDialog: Nullable<string> = null;

  private get sleepDate() {
    const sleepUnitlDate = new Date(this.chatbotGlobalSetting.sleepUntil);
    const now = new Date();

    if (sleepUnitlDate >= now) {
      return this.humanReadableDate(sleepUnitlDate);
    }

    const timeString = `${this.form.sleepTimeNumber} ${this.form.sleepTimeLabel}`; // Example input
    const sleepDurationMs = this.getSleepDurationInMs(timeString);
    const newSleepTime = new Date(now.getTime() + sleepDurationMs);
    return this.humanReadableDate(newSleepTime);
  }

  private getSleepDurationInMs(timeString: string): number {
    // Split the string into a number and a unit (e.g., "1 Hours" becomes ["1", "Hours"])
    const [amountStr, unit] = timeString.split(" ");
    const amount = parseInt(amountStr, 10);

    if (Number.isNaN(amount)) {
      throw new Error("Invalid time format");
    }

    // Convert based on the unit
    switch (unit.toLowerCase()) {
      case "hours":
        return amount * 60 * 60 * 1000;
      case "minutes":
        return amount * 60 * 1000;
      case "seconds":
        return amount * 1000;
      default:
        throw new Error("Unsupported time unit");
    }
  }

  private channels = [
    {
      text: "Facebook Messenger",
      value: "messenger",
    },
    {
      text: "Facebook",
      value: "facebook_comment",
    },
    // {
    //   text: 'Instagram',
    //   value: 'instagram',
    // },
    // {
    //   text: 'Instagram DM',
    //   value: 'instagram_dm',
    // },
  ];

  private sleepOptions = [
    {
      text: "All users",
      value: "all_users",
      tooltipText:
        "If a message is sent to any user manually, the bot will be put to sleep for all users.",
    },
    {
      text: "Manually-messaged users only",
      value: "manually_messaged_users",
      tooltipText:
        "The bot will be put to sleep only for users who the merchant manually messages.",
    },
  ];

  private timeLabels = ["Minutes", "Hours"];

  private minuteLabel = ["Minutes"];

  private form = {
    operationMode: "copilot",
    selectedChannels: [],
    waitTimeNumber: 5,
    waitTimeLabel: "Minutes",
    maxMessage: "10",
    sleepTimeNumber: 1,
    sleepTimeLabel: "Hours",
    applyTo: "all_users",
  };

  private get sleepMin() {
    if (this.form.sleepTimeLabel === "Minutes") {
      return "10";
    }
    return "1";
  }

  private get sleepMax() {
    if (this.form.sleepTimeLabel === "Minutes") {
      return "59";
    }
    return "24";
  }

  private get optionsErrors() {
    const errors: TranslateResult[] = [];

    // Ensure the validation object is dirty (i.e., has been interacted with)
    if (!this.$v.form.selectedChannels?.$dirty) {
      return errors;
    }

    // Check if the required validation has failed
    if (!this.$v.form.selectedChannels?.required) {
      const msg = this.vm.requiredFieldMessage("Channels");
      errors.push(msg);
    }

    return errors;
  }

  private get delayErrors() {
    const errors: TranslateResult[] = [];

    // Ensure the validation object is dirty (i.e., has been interacted with)
    if (!this.$v.form.waitTimeNumber?.$dirty) {
      return errors;
    }

    // Check if the required validation has failed
    if (!this.$v.form.waitTimeNumber?.required) {
      const msg = this.vm.requiredFieldMessage("Delay");
      errors.push(msg);
    }

    if (!this.$v.form.waitTimeNumber?.invalidDelay) {
      const msg = "Please select between 1 and 120";
      errors.push(msg);
    }

    return errors;
  }

  private withinDelayValues(obj: any) {
    const numericObj = Number(obj);
    if (
      (numericObj >= 1 && numericObj <= 120) ||
      this.selectedOption === 2 ||
      this.selectedOption === 1
    ) {
      return true;
    }
    return false;
  }

  private get sleepErrors() {
    const errors: TranslateResult[] = [];

    // Ensure the validation object is dirty (i.e., has been interacted with)
    if (!this.$v.form.sleepTimeNumber?.$dirty) {
      return errors;
    }

    // Check if the required validation has failed
    if (!this.$v.form.sleepTimeNumber?.required) {
      const msg = this.vm.requiredFieldMessage("Delay");
      errors.push(msg);
    }

    if (!this.$v.form.sleepTimeNumber?.invalidSleep) {
      let msg = '';
      if (this.form.sleepTimeLabel === "Minutes") {
        msg = "Please select between 10 and 59";
      }

      if (this.form.sleepTimeLabel === "Hours") {
        msg = "Please select between 1 and 24";
      }
      errors.push(msg);
    }

    return errors;
  }

  private withinSleepValues(obj: any) {
    const numericObj = Number(obj);
    if (
      (this.form.sleepTimeLabel === "Minutes" &&
        numericObj >= 10 &&
        numericObj <= 59) ||
      this.selectedOption === 2 ||
      this.selectedOption === 1
    ) {
      return true;
    }

    if (
      (this.form.sleepTimeLabel === "Hours" &&
        numericObj >= 1 &&
        numericObj <= 24) ||
      this.selectedOption === 2 ||
      this.selectedOption === 1
    ) {
      return true;
    }

    return false;
  }

  getOptionByIndex(index: number) {
    return this.options.find((option) => option.index === index);
  }

  private humanReadableDate(date: any) {
    return moment(date).format("MMM DD YYYY h:mm A");
  }

  private get isAsleep() {
    const vm = this as any;
    if (
      vm.selectedOption === 3 &&
      vm.chatbotGlobalSetting &&
      (new Date(vm.chatbotGlobalSetting.sleepUntil) >= new Date() || this.asleep)
    ) {
      return true;
    }
    return false;
  }

  private save() {
    const vm = this as any;

    vm.$v.$touch();

    if (!vm.$v.$invalid) {
      vm.saving = true;
      const operationValue = vm.getOptionByIndex(vm.selectedOption).value;

      let operationMode = "";
      if (operationValue === "Turn Off") {
        operationMode = "off";
      } else {
        operationMode = operationValue.toLowerCase();
      }

      const delay = `${vm.form.waitTimeNumber} ${vm.form.waitTimeLabel}`;

      const sleepDuration = `${vm.form.sleepTimeNumber} ${vm.form.sleepTimeLabel}`;

      const channels = vm.form.selectedChannels.map((channel: any) => {
        return channel.value;
      });

      const sleepOption = vm.form.applyTo;

      const sleep = this.asleep;

      const variables: any = {
        input: {
          appUuid: vm.$route.params.merchantUuid,
          operationMode,
          channels,
          delay,
          sleep,
          sleepDuration,
          sleepOption,
        },
      };

      return vm.$apollo
        .mutate({
          mutation: SaveGlobalChatbotSettings,
          variables,
        })
        .then(() => {
          this.showSuccessAlert(`Successfully Saved Chatbot Settings`);
          vm.saving = false;
        })
        .catch((e) => {
          vm.saving = false;
          this.showErrorAlert(e.message);
        })
        .finally(() => {
          this.$apollo.queries.chatbotGlobalSetting.refetch();
        });
    }
  }

  private wakeUpChatbot() {
    const vm = this as any;
    this.asleep = false;

    const variables: any = {
      input: {
        appUuid: vm.$route.params.merchantUuid,
      },
    };

    return vm.$apollo
      .mutate({
        mutation: SleepChatbot,
        variables,
      })
      .then(() => {
        this.showSuccessAlert(`Successfully Turned on Chatbot`);
        this.showConfirmationDialog = null;
      })
      .catch((e) => {
        this.showErrorAlert(e.message);
      })
      .finally(() => {
        this.$apollo.queries.chatbotGlobalSetting.refetch();
      });
  }

  private sleepChatbot() {
    this.asleep = true;
  }

  private delay = false;

  private sleepAfterMessage = false;
}
