import gql from "graphql-tag";

export const adminFileExported = gql`
  subscription adminFileExported(
    $exportFile: AdminExportType!
    $payment: AdminPayment
  ) {
    adminFileExported(exportFile: $exportFile, paymentType: $payment)
  }
`;
