
// Core
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// Extends
// Children
// Models / Helpers
// Queries / Mutations

@Component
export default class PromptContext extends Vue {
  @Prop({ type: Object, default: null })
  private appSnapshot!: any;

  @Prop({ type: Object, default: null })
  private promptContext!: any;

  private targetAudienceLabels = {
    ageRange: "Age Range",
    educationLevel: "Education Level",
    familyStatus: "Family Status",
    gender: "Gender",
    incomeLevel: "Income Level",
    interestHobbies: "Interest and Hobbies",
    occupation: "Occupation",
    personality: "Personality Traits",
    region: "Specific Region (Philippine Regions)",
  };

  private get targetAudience() {
    let { targetAudience } = this.promptContext;
    if (!targetAudience) {
      return null;
    }

    targetAudience = Object.keys(targetAudience).filter(
      (key) => targetAudience[key] !== null
    );

    return targetAudience.filter((el: string) => el !== "__typename");
  }

  private humanReadableDate(date) {
    date = new Date(date);
    return date.toDateString();
  }
}
