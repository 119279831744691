
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseAlert extends Vue {
  @Prop({ type: String, default: "success" })
  private alertType!: string;

  @Prop({ type: String, default: "" })
  private message!: string;

  @Prop({ type: Boolean, default: false })
  private value!: boolean;
}
