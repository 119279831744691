import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import NotifierModule from "../store/modules/NotifierModule";

@Component
export default class NotifierMixin extends Vue {
  private timeout = 5000;

  public get notifierModule() {
    return getModule(NotifierModule, this.$store);
  }

  public showSuccessAlert(message = "") {
    this.notifierModule.onToggleAlert();
    this.notifierModule.setMessage(message);
    this.notifierModule.setAlertType("success");
    window.setTimeout(() => this.notifierModule.onToggleAlert(), this.timeout);
  }

  public showErrorAlert(message = "") {
    this.notifierModule.onToggleAlert();
    this.notifierModule.setMessage(message.replace("GraphQL error: ", ""));
    this.notifierModule.setAlertType("red");
    window.setTimeout(() => this.notifierModule.onToggleAlert(), this.timeout);
  }
}
