import gql from "graphql-tag";
import { Order } from "../fragments/order";
import { Connection } from "../fragments/connection";

export const adminOrders = gql`
  query orderStatuses(
    $status: OrderStatuses
    $requiredKyc: Boolean
    $thirdParty: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
    $keyword: String
    $deliveryIds: [Int!]
    $distributorId: Int
    $distributor: Boolean
  ) {
    adminOrders: orderStatuses(
      status: $status
      requiredKyc: $requiredKyc
      thirdParty: $thirdParty
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      deliveryIds: $deliveryIds
      distributorId: $distributorId
      distributor: $distributor
    ) {
      edges {
        node {
          ...OrderStatus
          appName
          pageId
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${Connection.fragments.pageInfo}
  ${Order.fragments.orderStatus}
`;

export const orderDetails = gql`
  query order($orderUuid: ID!) {
    orderDetails: ecommerceOrder(orderUuid: $orderUuid) {
      ...Order
    }
  }
  ${Order.fragments.orderFull}
`;
