
// Core
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// Extends
// Children
// Models / Helpers
// Queries / Mutations

@Component
export default class CopilotNotes extends Vue {
  @Prop({ type: Object, default: null })
  private completion!: any;
}
