
// Core
import { Component, Mixins } from "vue-property-decorator";
// Extends
import ExtendedPromptMixin from "@/mixins/ExtendedPromptMixin";
// Children
import CopilotNotes from "@/components/GenAI/CopilotNotes.vue";
import PromptCard from "@/components/GenAI/PromptCard.vue";
import PromptContext from "@/components/GenAI/PromptContext.vue";
// Models / Helpers
// Queries / Mutations

@Component({
  components: {
    CopilotNotes,
    PromptCard,
    PromptContext,
  },
})
export default class StoreLaunchPrompt extends Mixins(ExtendedPromptMixin) {
  private humanReadableDate(date) {
    date = new Date(date);
    return date.toDateString();
  }

  private get voucherValue() {
    if (this.prompt?.promoCode.valueType === "percentage") {
      return `${this.prompt?.promoCode.value}%`;
    }
    return `₱${this.prompt?.promoCode.value}`;
  }
}
