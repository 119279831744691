import { gql } from "graphql-tag";
import { Billing } from "../fragments/billing";

export const billingApprove = gql`
  mutation billingApprove($input: BillingApproveInput!) {
    billingApprove(input: $input) {
      billing {
        ...BillingBasic
      }
    }
  }
  ${Billing.fragments.billingBasic}
`;

export const billingReject = gql`
  mutation billingReject($input: BillingRejectInput!) {
    billingReject(input: $input) {
      billing {
        ...BillingBasic
      }
    }
  }
  ${Billing.fragments.billingBasic}
`;

export const billingCreate = gql`
  mutation billingCreate($input: BillingCreateInput!) {
    billingCreate(input: $input) {
      billing {
        ...BillingBasic
      }
    }
  }
  ${Billing.fragments.billingBasic}
`;

export const updateCreditStatus = gql`
  mutation updateCreditStatus($input: updateCreditStatusInput!) {
    updateCreditStatus(input: $input) {
      credit {
        ...Credit
      }
    }
  }
  ${Billing.fragments.credit}
`;

export const billingExport = gql`
  mutation exportBillingOrders($input: ExportBillingPeriodOrdersInput!) {
    exportBillingOrders(input: $input) {
      billing {
        ...BillingBasic
      }
    }
  }
  ${Billing.fragments.billingBasic}
`;
