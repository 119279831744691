var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app-bar',{class:{
    'top-nav': true,
    '-mobile': _vm.breakpoint.smAndDown,
  },attrs:{"app":"","clipped-left":"","flat":"","height":_vm.topNavHeight,"dark":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('img',{class:{
        'brand-image': true,
        '-mobile': _vm.breakpoint.smAndDown,
      },attrs:{"src":require('../assets/chatgenie-logo-beta.svg')}}),(
        _vm.authenticated && (_vm.breakpoint.mdAndDown || _vm.breakpoint.width <= 1500)
      )?_c('v-app-bar-nav-icon',{staticClass:"mx-6",nativeOn:{"click":function($event){return _vm.$emit('open:drawer')}}}):_vm._e()],1),_c('v-spacer'),(_vm.authenticated && !_vm.loading)?_c('v-toolbar-items',{staticClass:"menu-buttons"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":50,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","id":"avatar-btn"}},'v-btn',attrs,false),on),[_c('div',[_c('v-avatar',{attrs:{"size":_vm.avatarSize}},[(_vm.me.imageUrl)?_c('img',{attrs:{"src":_vm.me.imageUrl,"height":"48"}}):_c('v-icon',{attrs:{"size":_vm.avatarSize}},[_vm._v("mdi-account-circle")])],1),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)])]}}],null,false,557388405),model:{value:(_vm.settingsMenu),callback:function ($$v) {_vm.settingsMenu=$$v},expression:"settingsMenu"}},[_c('v-list',{staticClass:"px-2 user-settings",attrs:{"dense":"","rounded":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onLogOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1)],1)],1)],1):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mr-4",attrs:{"type":"avatar"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }