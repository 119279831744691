import { Component, Vue } from "vue-property-decorator";

@Component
export default class LocalizationMixin extends Vue {
  public get serverError() {
    return this.$t("graphql.error.500");
  }

  public requiredFieldMessage(name = "Field") {
    return this.$t("validations.required.field", { name });
  }

  public invalidFieldMessage(name = "Field") {
    return this.$t("validations.invalid", { name });
  }

  public uniqueFieldMessage(name = "Field") {
    return this.$t("validations.unique", { name });
  }
}
