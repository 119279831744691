
import { Nullable } from "@/@types/BaseTypes";
import { PaymentGateway, PaymentOptionDetail } from "@/@types/Payment";
import {
  paymentOptionCreate,
  paymentOptionUpdate,
} from "@/api/mutations/merchant";
import FormMixin from "@/mixins/FormMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";

@Component({
  validations: {
    displayName: { required },
    paymentDetails: {
      $each: {
        content: { required },
      },
    },
  },
})
export default class PaymentOptionFormCod extends Mixins(
  LoaderMixin,
  FormMixin
) {
  @Prop({ type: String, default: "" })
  private value!: string;

  @Prop({ type: String, default: "" })
  private title!: string;

  @Prop({ type: Object, default: null })
  private paymentMethod!: Nullable<PaymentGateway>;

  private displayName = "";

  // { optionDetailUuid, content }
  private paymentDetails: PaymentOptionDetail[] = [];

  private appendPaymentDetail() {
    // notice, alert, reminder
    this.paymentDetails.push({ content: "" });
  }

  private create() {
    return this.$apollo.mutate({
      mutation: paymentOptionCreate,
      variables: {
        input: {
          paymentGatewayUuid: this.paymentMethod?.uuid,
          details: this.paymentDetails,
          appUuid: this.$route.params.merchantUuid,
          paymentName: this.displayName,
        },
      },
    });
  }

  private update() {
    return this.$apollo.mutate({
      mutation: paymentOptionUpdate,
      variables: {
        input: {
          details: this.paymentDetails,
          optionUuid: this.paymentMethod?.appPaymentOption?.uuid,
          paymentName: this.displayName,
        },
      },
    });
  }

  private async onSubmit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();
      const mutation = !this.paymentMethod?.appPaymentOption
        ? this.create
        : this.update;

      mutation()
        .then(() => {
          this.loadingDone();
          this.$emit("form:success", "Payment Setting updated.");
          this.$emit("dialog:close");
        })
        .catch((e) => {
          this.loadingDone();
          this.$emit("form:error", e.message);
          this.$emit("dialog:close");
        });
    }
  }
  private removePaymentDetail(idx: number) {
    this.paymentDetails = this.paymentDetails.filter(
      (_value: PaymentOptionDetail, key: number) => key !== idx
    );
  }

  private mounted() {
    this.appendPaymentDetail();

    if (this.paymentMethod?.appPaymentOption) {
      const option = this.paymentMethod?.appPaymentOption;

      this.displayName = option.paymentName;
      this.paymentDetails = option.optionDetails.map((optionDetail: any) => ({
        content: optionDetail.content,
      }));
    }
  }
}
