import { Nullable } from "@/@types/BaseTypes";
import { CartItem, CartItemOptions } from "@/@types/Cart";
import { ItemType } from "@/@types/enums/Item";
import { CartItemImpl } from "@/@types/implementations/ItemImpl";
import { currency, numeral } from "@/utils/helpers/numeric-formatter";

export class CartItemModel implements CartItemImpl {
  checkoutPrice!: number;
  itemOptions!: CartItemOptions[];
  itemTotal!: number;
  productName!: string;
  productPrimaryPhoto!: string;
  quantity!: number;
  remarks!: Nullable<string>;
  remarksLabel!: Nullable<string>;
  skuMatCode!: Nullable<string>;
  uuid!: string;
  itemType!: ItemType;

  constructor(item: CartItem) {
    Object.assign(this, item);
  }

  public get displayedSubtotal() {
    return currency(this.itemTotal);
  }

  public get displayedQty() {
    return numeral(this.quantity);
  }

  public get varianceEnabled() {
    return this.itemOptions.length > 0;
  }
}
