import { Mixins, Component } from "vue-property-decorator";
import { snakeCase } from "lodash-core";
import { Nullable } from "@/@types/BaseTypes";
import LoaderMixin from "@/mixins/LoaderMixin";
import { billingApprove, billingReject } from "@/api/mutations/billing";
import NotifierMixin from "@/mixins/NotifierMixin";

@Component
export default class BillingActionMixin extends Mixins(
  LoaderMixin,
  NotifierMixin
) {
  public previewedDepositSlip = null;

  public billingConfirmationMessage = "";

  public billingUuid: Nullable<string> = null;

  public eventEmitter = "billing-approve";

  public latestDepositSlip(billing: any) {
    const { depositSlips } = billing;

    return depositSlips.length > 0
      ? depositSlips.map(
          ({ originalUrl }: { originalUrl: string }) => originalUrl
        )[0]
      : null;
  }

  public isPaid(status: string) {
    return status.toLowerCase() === "paid";
  }

  public forPayment(status: string) {
    return snakeCase(status.toLowerCase()) === "for_payment";
  }

  public paymentSubmitted(status: string) {
    return snakeCase(status.toLowerCase()) === "payment_submitted";
  }

  public get billingsLoading() {
    return this.$apollo.queries.billings.loading;
  }

  public get isBillingApproveEmitter() {
    return this.eventEmitter === "billing-approve";
  }

  public onDisplayApprovedBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be approved. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-approve";
  }

  public onDisplayRejectBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be rejected. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-reject";
  }

  public onBillingStatusUpdate() {
    this.loadingInProgress();
    const mutation = this.isBillingApproveEmitter
      ? billingApprove
      : billingReject;
    const message = this.isBillingApproveEmitter ? "Approved" : "Rejected";

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { billingUuid: this.billingUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.showSuccessAlert(`Billing ${message}`);
          this.loadingDone();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }
}
