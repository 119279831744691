export enum Plans {
  all = "",
  freeStarter = "free_starter",
  freeTrial = "free_trial",
  proMsme = "pro_msme",
  proStarter = "pro_starter",
  proBronze = "pro_bronze",
  proSilver = "pro_silver",
  proGold = "pro_gold",
  proPlatinum = "pro_platinum",
  proDiamond = "pro_diamond",
  proMythical = "pro_mythical",
  proPremium = "pro_premium",
}

export enum CreditStatuses {
  pending = "pending",
  approved = "approved",
  failed = "failed",
}

export enum CreditPaymentTypes {
  gcash = "gcash",
  bpi = "bpi",
  bank = "bank",
}
