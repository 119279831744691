import { render, staticRenderFns } from "./CopilotNotes.vue?vue&type=template&id=995a2bf2&scoped=true&"
import script from "./CopilotNotes.vue?vue&type=script&lang=ts&"
export * from "./CopilotNotes.vue?vue&type=script&lang=ts&"
import style0 from "./CopilotNotes.vue?vue&type=style&index=0&id=995a2bf2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "995a2bf2",
  null
  
)

export default component.exports