
import NotifierMixin from "@/mixins/NotifierMixin";
import { Component, Mixins } from "vue-property-decorator";
import { billing as query } from "@/api/queries/all-billings";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import ConvenienceFeeDetails from "@/pages/BillingManager/ConvenienceFeeDetails.vue";
import { shortDateOne } from "@/utils/helpers/date-formatter";
import { snakeCase } from "lodash-core";
import GracePeriodActivation from "@/pages/BillingManager/GracePeriodActivation.vue";
import appDeactivated from "@/api/queries/app-deactivated.gql";

@Component({
  components: {
    ConvenienceFeeDetails,
    GracePeriodActivation,
  },
  apollo: {
    billing: {
      query,
      variables() {
        return {
          billingUuid: this.$route.params.billingUuid,
        };
      },
      update({ billing }: any) {
        this.billing = billing;
        return this.billing;
      },
    },
    appDeactivated: {
      query: appDeactivated,
      variables() {
        return {
          appUuid: this.billing.app.uuid,
        };
      },
      skip() {
        return !this.billing;
      },
      update(data: any) {
        this.appDeactivated = data.app?.deactivated;
        return data.app?.deactivated;
      },
    },
  },
})
export default class BillingDetails extends Mixins(
  NotifierMixin,
  BreakpointMixin
) {
  private loader = false;

  private billing = null;

  private appDeactivated = false;

  private refetchQueries() {
    this.$apollo.queries.appDeactivated.refetch();
    this.$apollo.queries.billing.refetch();
  }

  private hasDepositSlip(count: number): boolean {
    return count > 0;
  }

  private dateFormatter(date) {
    return shortDateOne(date);
  }

  private isPaid(status: string) {
    return status.toLowerCase() === "paid";
  }

  private forPayment(status: string) {
    return snakeCase(status.toLowerCase()) === "for_payment";
  }

  private paymentSubmitted(status: string) {
    return snakeCase(status.toLowerCase()) === "payment_submitted";
  }

  private capitalized(text: string) {
    return text
      .toLowerCase()
      .replace(/\b[a-z](?=[a-z]{2})/g, (letter: string) => {
        return letter.toUpperCase();
      });
  }

  private statusFormatter(status) {
    const normalizedText = status.replace(/_/g, " ");
    return this.capitalized(normalizedText);
  }

  private currencyFormatter(amount) {
    const float = parseFloat(amount);
    const formattedAmount = float.toFixed(2);
    return `₱ ${formattedAmount}`;
  }
}
