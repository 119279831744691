import gql from "graphql-tag";

export const enterprisePlan = gql`
  fragment EnterprisePlan on EnterprisePlan {
    uuid
    name
    displayedName
    subscriptionFee
    accelerator
  }
`;

export const Plan = {
  fragments: { enterprisePlan },
};
