import gql from "graphql-tag";

const cartItem = gql`
  fragment Item on Item {
    uuid
    productPrimaryPhoto
    productName
    quantity
    checkoutPrice
    itemTotal
    remarksLabel
    remarks
    skuMatCode
    itemOptions {
      edges {
        node {
          optionName
          sectionName
        }
      }
    }
  }
`;

const promoCode = gql`
  fragment PromoCode on PromoCode {
    uuid
    active
    code
    createdAt
    endAt
    startAt
    usageLimit
    value
    valueType
    usageCount
  }
`;

export const Product = {
  fragments: { cartItem, promoCode },
};
