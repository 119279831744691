import decoder from "jwt-decode";
import moment from "moment-mini";
import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";
import { AUTH_TOKEN_KEY, JWT_HMAC_SECRET, AES_SECRET_KEY } from "../constants";
import { Nullable } from "../../@types/BaseTypes";
import { JWT as Token } from "../../@types/Auth";

export default class JWT {
  constructor(token: Nullable<string> = localStorage.getItem(AUTH_TOKEN_KEY)) {
    this.token = token || "";
  }

  public token!: string;

  private get decodedJwt() {
    const decodedToken = this.decrypt(this.token);
    return decoder(decodedToken);
  }

  public get isValid() {
    let decodedJWT: Token;

    try {
      // console.log(this.decodedJwt);
      decodedJWT = this.decodedJwt as Token;
    } catch (e) {
      // Handle falsey decode of jwt
      return false;
    }
    // token is not expired if .exp is not undefined and .exp is greater than or equal current
    // false everything else false to this measure?
    // return decodedJWT.exp !== undefined && decodedJWT.exp >= moment().unix();
    return true;
  }

  public decrypt(token: string) {
    const encodedToken = CryptoJS.enc.Base64.parse(token);
    const base64Token = encodedToken
      .toString(CryptoJS.enc.Utf8)
      .split("\n")
      .join("");
    const received = CryptoJS.enc.Base64.parse(base64Token);
    const salt = CryptoJS.enc.Hex.parse(received.toString().slice(0, 32));
    const iv = CryptoJS.enc.Hex.parse(received.toString().slice(32, 64));
    const cipher = CryptoJS.enc.Hex.parse(received.toString().slice(64));
    const key = CryptoJS.PBKDF2(AES_SECRET_KEY, salt, {
      keySize: 256 / 32,
      iterations: 100,
    });

    const decodedCipher = CryptoJS.AES.decrypt(
      cipher.toString(CryptoJS.enc.Base64),
      key,
      {
        iv,
      }
    ).toString(CryptoJS.enc.Utf8);

    return decodedCipher;
  }

  private encrypt(payload: string) {
    const iv = CryptoJS.lib.WordArray.random(16);
    const salt = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.PBKDF2(AES_SECRET_KEY, salt, {
      keySize: 256 / 32,
      iterations: 100,
    });
    const cipher = CryptoJS.AES.encrypt(payload, key, {
      iv,
    });
    const cipheredToken = CryptoJS.enc.Hex.parse(
      salt.toString() + iv.toString() + cipher.ciphertext.toString()
    ).toString(CryptoJS.enc.Base64);

    const decodeCiphered = CryptoJS.enc.Utf8.parse(cipheredToken);

    return CryptoJS.enc.Base64.stringify(decodeCiphered);
  }

  public setToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    this.token = token;
  }

  public removeToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
}

export const jwtDefaultBearerGenerator = (token: Nullable<string> = null) => {
  const defaultToken = jwt.sign(
    { code: moment().unix() },
    `${JWT_HMAC_SECRET}`
  );
  return `Bearer ${token || defaultToken}`;
};

export const captchaSignature = (payload: any) => {
  return jwt.sign(payload, `${JWT_HMAC_SECRET}`);
};
