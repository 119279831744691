
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseButton extends Vue {
  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private block!: boolean;

  @Prop({ type: Boolean, default: false })
  private xSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  private xLarge!: boolean;

  @Prop({ type: Boolean, default: false })
  private small!: boolean;

  @Prop({ type: Boolean, default: false })
  private large!: boolean;

  @Prop({ type: Boolean, default: false })
  private orange!: boolean;

  @Prop({ type: Boolean, default: false })
  private export!: boolean;

  @Prop({ type: Boolean, default: false })
  private deleteVariant!: boolean;

  @Prop({ type: Boolean, default: false })
  private edit!: boolean;

  @Prop({ type: Boolean, default: false })
  private activeVariant!: boolean;

  @Prop({ type: Boolean, default: false })
  private long!: boolean;

  @Prop({ type: Boolean, default: false })
  private flat!: boolean;

  private get buttonClasses() {
    return {
      "-orange": this.orange,
      "-long": this.long,
      "-export": this.export,
      "-delete": this.deleteVariant,
      "-edit": this.edit,
      "-active": this.activeVariant,
    };
  }
}
