
import { Vue, Component } from "vue-property-decorator";
import SalesPartnerBillingExport from "@/components/SalesPartnerBillingExport.vue";

@Component({
  components: {
    SalesPartnerBillingExport,
  },
})
export default class SalesPartners extends Vue {
  private get isSalesPartnerBillings() {
    return this.$route.name === "sales-partner-billings";
  }
}
