
import { Nullable } from "@/@types/BaseTypes";
import { PaymentGateway, PaymentOptionBankDetail } from "@/@types/Payment";
import FormMixin from "@/mixins/FormMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import {
  paymentOptionCreate,
  paymentOptionUpdate,
} from "@/api/mutations/merchant";

@Component({
  validations() {
    const vm = this as any;
    const uniqueBankName = (val: string) =>
      vm.bankDetails.filter(
        ({ bankName }: { bankName: string }) => val === bankName
      ).length < 2;

    const uniqueAccountNumber = (val: string) =>
      vm.bankDetails.filter(
        ({ accountNumber }: { accountNumber: string }) => val === accountNumber
      ).length < 2;

    return {
      displayName: { required },
      bankDetails: {
        $each: {
          bankName: {
            required,
            unique: uniqueBankName,
          },
          accountName: { required },
          accountNumber: {
            required,
            unique: uniqueAccountNumber,
          },
        },
      },
    };
  },
})
export default class PaymentOptionFormBank extends Mixins(
  LoaderMixin,
  FormMixin
) {
  @Prop({ type: String, default: "" })
  private value!: string;

  @Prop({ type: String, default: "" })
  private title!: string;

  @Prop({ type: Object, default: null })
  private paymentMethod!: Nullable<PaymentGateway>;

  private displayName = "";

  private bankDetails: PaymentOptionBankDetail[] = [];

  private addBankDetail() {
    this.bankDetails.push({
      bankName: "",
      accountName: "",
      accountNumber: "",
    });
  }

  private create() {
    return this.$apollo.mutate({
      mutation: paymentOptionCreate,
      variables: {
        input: {
          paymentGatewayUuid: this.paymentMethod?.uuid,
          bankDetails: this.bankDetails,
          appUuid: this.$route.params.merchantUuid,
          paymentName: this.displayName,
        },
      },
    });
  }

  private update() {
    return this.$apollo.mutate({
      mutation: paymentOptionUpdate,
      variables: {
        input: {
          bankDetails: this.bankDetails,
          optionUuid: this.paymentMethod?.appPaymentOption?.uuid,
          paymentName: this.displayName,
        },
      },
    });
  }

  private async onSubmit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();
      const mutation = !this.paymentMethod?.appPaymentOption
        ? this.create
        : this.update;

      mutation()
        .then(() => {
          this.loadingDone();
          this.$emit("form:success", "Payment Setting updated.");
          this.$emit("dialog:close");
        })
        .catch((e) => {
          this.loadingDone();
          this.$emit("form:error", e.message);
          this.$emit("dialog:close");
        });
    }
  }

  private removeBankDetail(idx: number) {
    this.bankDetails = this.bankDetails.filter(
      (_value: PaymentOptionBankDetail, key: number) => key !== idx
    );
  }

  private mounted() {
    this.addBankDetail();

    if (this.paymentMethod?.appPaymentOption) {
      const option = this.paymentMethod?.appPaymentOption;

      this.displayName = option.paymentName;
      this.bankDetails = option.bankDetails.map((bankDetail: any) => ({
        accountName: bankDetail.accountName,
        accountNumber: bankDetail.accountNumber,
        bankName: bankDetail.bankName,
      }));
    }
  }
}
