import { VueConstructor } from "vue/types/umd";

// Gets all files with Base prefix
const requireComponent = require.context(
  "../components",
  false,
  /Base[\w]+\.vue$/
);

const componentParser = (fileName: string) => {
  return fileName
    .replace(/^\.\//, "")
    .replace(/\.\w+$/, "")
    .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
    .toLowerCase();
};

export const importComponents = (Vue: VueConstructor) => {
  requireComponent.keys().forEach((fileName: string) => {
    const componentConfig = requireComponent(fileName);
    const componentName = componentParser(fileName);

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};
