import gql from "graphql-tag";

const billingBasic = gql`
  fragment BillingBasic on Billing {
    uuid
    amount
    currentAmount
    balance
    referenceNumber
    tierType
    billingType
    billingPeriodStartAt
    billingPeriodEndAt
    billingPeriodDueAt
    status
    disabled
    enterpriseBasedAmount
    enterpriseBasedAccumulatedAmount
    gracePeriodActivated
    accumulatedAmount
    accumulatedShippingFee
    salesPartnerCommission
    paymentReceivedAt
    ordersBaseOnConvenienceFee {
      transactionNumber
      orderStatus {
        status
      }
    }
    collected
    depositSlips {
      originalUrl
    }
    app {
      uuid
      plan
      name
      subscribedEnterprisePlan {
        uuid
        name
      }
    }
  }
`;

const billingDetail = gql`
  fragment BillingDetail on BillingDetail {
    uuid
    amount
    convenienceFee
    planName
    billingPeriodStartAt
    billingPeriodEndAt
  }
`;

const billingFull = gql`
  fragment BillingFull on Billing {
    ...BillingBasic
    details {
      ...BillingDetail
    }
    ordersBaseOnConvenienceFee {
      transactionNumber
      orderStatus {
        status
      }
    }
  }
  ${billingBasic}
  ${billingDetail}
`;

const referralBilling = gql`
  fragment ReferralBilling on Billing {
    uuid
    referenceNumber
    billingPeriodStartAt
    billingPeriodEndAt
    billingPeriodDueAt
    currentAmount
    status
    depositSlips {
      originalUrl
    }
    app {
      name
    }
  }
`;

export const credit = gql`
  fragment Credit on Credit {
    uuid
    amount
    processingFee
    total
    referenceNumber
    status
    paymentType
    createdAt
    depositSlips {
      url
    }
  }
`;

export const Billing = {
  fragments: {
    billingBasic,
    billingDetail,
    billingFull,
    credit,
    referralBilling,
  },
};
