
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

/**
 * Close Icon
 * @author Jan Canicon <jan@gorated.ph>
 *                     <jancanicon.work@gmail.com>
 * @since Version {1.4.1}
 *
 * Docs: https://gorated.atlassian.net/wiki/spaces/DMDS/pages/1856045312/Close+Icon
 */
@Component
export default class CloseIcon extends Vue {
  @Prop({ type: Boolean, default: null })
  private topleft!: boolean;

  @Prop({ type: Boolean, default: null })
  private topright!: boolean;

  @Prop({ type: Boolean, default: null })
  private bottomleft!: boolean;

  @Prop({ type: Boolean, default: null })
  private bottomright!: boolean;

  @Prop({ type: String, default: null })
  private iconclass!: string;

  @Prop({ type: String, default: "2.5em" })
  private fontSize!: string;

  private get styles() {
    return {
      top: this.topleft || this.topright ? 0 : null,
      bottom: this.bottomleft || this.bottomright ? 0 : null,
      left: this.topleft || this.bottomleft ? 0 : null,
      right: this.topright || this.bottomleft ? 0 : null,
      "font-size": this.fontSize,
    };
  }
}
