
// Core
import { Component, Mixins } from "vue-property-decorator";
// Extends
import ExtendedPromptMixin from "@/mixins/ExtendedPromptMixin";
// Children
import CopilotNotes from "@/components/GenAI/CopilotNotes.vue";
import PromptCard from "@/components/GenAI/PromptCard.vue";
import PromptContext from "@/components/GenAI/PromptContext.vue";
// Models / Helpers
// Queries / Mutations

@Component({
  components: {
    CopilotNotes,
    PromptCard,
    PromptContext,
  },
})
export default class DescriptionPrompt extends Mixins(ExtendedPromptMixin) {
  private targetAudienceLabels = {
    ageRange: "Age Range",
    educationLevel: "Education Level",
    familyStatus: "Family Status",
    gender: "Gender",
    incomeLevel: "Income Level",
    interestHobbies: "Interest and Hobbies",
    occupation: "Occupation",
    personality: "Personality Traits",
    region: "Specific Region (Philippine Regions)",
  };

  private get imageLink() {
    const image = this.prompt.galleryPhoto?.photoUrl;
    if (!image) {
      return require("@/assets/cg-logo.png");
    }
    return image;
  }

  private get targetAudience() {
    let { targetAudience } = this.prompt.promptContext;
    if (!targetAudience) {
      return null;
    }

    targetAudience = Object.keys(targetAudience).filter(
      (key) => targetAudience[key] !== null
    );

    return targetAudience.filter((el) => el !== "__typename");
  }
}
