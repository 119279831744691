
import { merchantGrabTopUps as query } from "@/api/queries/merchants";
import { updateCreditStatus as mutation } from "@/api/mutations/billing";
import { node } from "@/utils/helpers/graphql";
import { currency } from "@/utils/helpers/numeric-formatter";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { startCase, snakeCase } from "lodash-core";
import { fullDateTime } from "@/utils/helpers/date-formatters";
import { CreditStatuses } from "@/@types/enums/Billing";
import { AttachedFile } from "@/@types/scalars/File";
import { PageInfo } from "@/@types/Page";
import { Nullable } from "@/@types/BaseTypes";
import { Credit } from "@/@types/Billing";
import NotifierMixin from "@/mixins/NotifierMixin";
import LoaderMixin from "@/mixins/LoaderMixin";

@Component({
  components: {
    ImgPreview: () => import("@/components/ImagePreviewDialog.vue"),
  },
  apollo: {
    grabTopUpList: {
      query,
      fetchPolicy: "cache-and-network",
      variables() {
        return this.queryVariables;
      },
      update({ grabTopUpList }: any) {
        if (!grabTopUpList) {
          return [];
        }

        const topUp = grabTopUpList.edges.map(node);
        this.$emit("data:page-info", grabTopUpList.pageInfo);

        return topUp.map((node: Credit) => ({
          ...node,
          amount: currency(node.amount),
          paymentType: startCase(node.paymentType).toUpperCase(),
          depositSlip: this.latestDepositSlip(node.depositSlips),
          createdAt: fullDateTime(node.createdAt),
          status: startCase(node.status),
        }));
      },
    },
  },
})
export default class MerchantTopupGrab extends Mixins(
  NotifierMixin,
  LoaderMixin
) {
  @Prop({ type: Number, default: 5 })
  private limit!: number;

  @Prop({ type: String, default: "" })
  private direction!: string;

  @Prop({ type: String, default: null })
  private startCursor!: Nullable<string>;

  private grabTopUpList = [];

  private pageInfo: Nullable<PageInfo> = null;

  private previewedDepositSlip = null;

  private eventEmitter = "credit-approve";

  private creditUuid: Nullable<string> = null;

  private creditConfirmationMessage = "";

  private get queryVariables() {
    return {
      appUuid: this.$route.params.merchantUuid,
      first: this.limit,
      after: this.startCursor,
    };
  }

  private get topUpsLoading() {
    return this.$apollo.queries.grabTopUpList.loading;
  }

  private get isCreditApproveEmitter() {
    return this.eventEmitter === "credit-approve";
  }

  private get headers() {
    const commonProps = {
      align: "start",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4",
    };

    return [
      {
        ...commonProps,
        text: "TOP-UP ID",
        value: "referenceNumber",
      },
      {
        ...commonProps,
        text: "Amount",
        value: "amount",
      },
      {
        ...commonProps,
        text: "Payment Method",
        value: "paymentType",
      },
      {
        ...commonProps,
        text: "Status",
        value: "status",
      },
      {
        ...commonProps,
        text: "Date & Time",
        value: "createdAt",
      },
      {
        ...commonProps,
        text: "Deposit Slip",
        value: "depositSlip",
      },
      {
        ...commonProps,
        align: "center",
        text: "Action",
        value: "action",
      },
    ];
  }

  private latestDepositSlip(depositSlips: AttachedFile[]) {
    return depositSlips.length > 0
      ? depositSlips.map(({ url }: { url: string }) => url)[0]
      : null;
  }

  private isApproved(status: CreditStatuses) {
    return snakeCase(status) === CreditStatuses.approved;
  }

  private isPending(status: CreditStatuses) {
    return snakeCase(status) === CreditStatuses.pending;
  }

  private isFailed(status: CreditStatuses) {
    return snakeCase(status) === CreditStatuses.failed;
  }

  private onCreditStatusUpdate() {
    this.loadingInProgress();
    const status = this.isCreditApproveEmitter
      ? CreditStatuses.approved
      : CreditStatuses.failed;
    const message = this.isCreditApproveEmitter ? "Approved" : "Rejected";

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { creditUuid: this.creditUuid, status },
        },
        update: () => {
          this.creditUuid = null;
          this.showSuccessAlert(`Grab express credit ${message}`);
          this.loadingDone();
        },
      })
      .catch((e) => {
        this.creditUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onDisplayApprovedCreditDialog(creditUuid: string) {
    this.creditConfirmationMessage =
      "Payment will be approved. Do you wish to continue?";
    this.creditUuid = creditUuid;
    this.eventEmitter = "credit-approve";
  }

  private onDisplayRejectCreditDialog(creditUuid: string) {
    this.creditConfirmationMessage =
      "Payment will be rejected. Do you wish to continue?";
    this.creditUuid = creditUuid;
    this.eventEmitter = "credit-reject";
  }
}
