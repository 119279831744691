
import { Mixins, Component, Prop } from "vue-property-decorator";
import { orderBy, kebabCase } from "lodash-core";
import { Nullable } from "@/@types/BaseTypes";
import { PaymentGateway } from "@/@types/Payment";
import NotifierMixin from "@/mixins/NotifierMixin";

@Component({
  components: {
    BankForm: () => import("@/components/PaymentOptionFormBank.vue"),
    ProviderForm: () => import("@/components/PaymentOptionFormProvider.vue"),
    CodForm: () => import("@/components/PaymentOptionFormCod.vue"),
    ThirdPartyForm: () =>
      import("@/components/PaymentOptionFormThirdParty.vue"),
    CreditCardForm: () =>
      import("@/components/PaymentOptionFormThirdParty.vue"),
  },
})
export default class MerchantSettingPayments extends Mixins(NotifierMixin) {
  @Prop({ type: Array, default: () => [] })
  private paymentMethods!: PaymentGateway[];

  @Prop({ type: Object, default: () => [] })
  private accountHolder!: any;

  @Prop({ type: String, default: "" })
  private endDate!: any;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  private componentType: Nullable<string> = null;

  private formTitle = "";

  private paymentMethod: Nullable<PaymentGateway> = null;

  private menu = false;

  private methodUuid = null;

  private get kycStatus() {
    return this.accountHolder?.kycStatus?.status ?? "No KYC submitted yet";
  }

  private get capabilities() {
    return this.accountHolder?.capabilities ?? [];
  }

  private get appUuid() {
    return this.$route.params.merchantUuid;
  }

  private get paymentOrderByType() {
    return orderBy(this.paymentMethods, ["paymentOptionType"]);
  }

  private onFormSuccess(msg: string) {
    this.showSuccessAlert(msg);
    this.$emit("query:refresh");
  }

  private openFormByType(method: PaymentGateway) {
    this.componentType = `${kebabCase(
      method.paymentOptionType.toLowerCase()
    )}-form`;
    this.formTitle = method.title;
    this.paymentMethod = method;
  }
}
