
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment-mini";
import {
  FULL_DATE_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  TIME_FORMAT,
} from "@/utils/constants";
import { Nullable } from "@/@types/BaseTypes";

@Component
export default class BaseDateTimePicker extends Vue {
  @Prop({ type: String, default: null })
  private value!: Nullable<string>;

  @Prop({ type: Array, default: [] })
  private errorMessages!: string[];

  @Prop({ type: String, required: true })
  private label!: string;

  private datetimePickerMenu = false;

  private tab = "date";

  private time = "00:00";

  private date = moment().format(SHORT_DATE_FORMAT);

  private get tabIcons() {
    return ["mdi-calendar", "mdi-clock"];
  }

  private get dateTime() {
    return `${this.date} ${this.time}`;
  }
  private get humanizedDateTime() {
    return moment(this.dateTime).format(FULL_DATE_TIME_FORMAT);
  }

  @Watch("value", { immediate: true })
  private valueObserver(value: string) {
    if (value) {
      this.date = moment(this.value as string).format(SHORT_DATE_FORMAT);
      this.time = moment(this.value as string).format(TIME_FORMAT);
      this.$emit("input", this.dateTime);
    }
  }
}
