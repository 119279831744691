import gql from "graphql-tag";

const pageInfo = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
`;

export const Connection = {
  fragments: { pageInfo },
};
