
import { Vue, Component } from "vue-property-decorator";
import { salesPartners } from "../api/queries/sales-partners";
import { currency } from "@/utils/helpers/numeric-formatter";
import { startCase } from "lodash-core";

@Component({
  apollo: {
    salesPartners: {
      query: salesPartners,
      update({ salesPartners }: any) {
        return salesPartners.edges.map(({ node }: any) => {
          return {
            ...node,
            resellerTotalBillings: currency(node.resellerTotalBillings),
            role: startCase(node.role),
            actions: node.uuid,
          };
        });
      },
    },
  },
})
export default class SalesPartnersIndex extends Vue {
  private salesPartners = [];

  private get headers() {
    const commonProps = {
      align: "center",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4 py-4",
    };

    return [
      {
        ...commonProps,
        text: "SALES PARTNER NAME",
        align: "start",
        value: "fullName",
      },
      {
        ...commonProps,
        text: "SALES PARTNER ID",
        align: "start",
        value: "affiliateCode",
      },
      {
        ...commonProps,
        text: "SALES PARTNER TYPE",
        align: "start",
        value: "role",
      },
      {
        ...commonProps,
        text: "TOTAL MINI APPS",
        align: "start",
        value: "referredMiniAppsCount",
      },
      {
        ...commonProps,
        text: "TOTAL BILL",
        align: "start",
        value: "resellerTotalBillings",
      },
      {
        ...commonProps,
        text: "",
        value: "actions",
      },
    ];
  }
}
