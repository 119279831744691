import gql from "graphql-tag";
import { Product } from "./product";
import { CustomerInfo } from "./customer";
import { Shipping } from "./shipping";

const orderStatusFull = gql`
  fragment OrderStatusFull on OrderStatus {
    additionalNotes
    cancelMessage
    cancelType
    cancelledAt
    completedAt
    confirmedAt
    createdAt
    estimateArriveDate
    paySuccessAt
    paymentStatus
    placedAt
    shipOutDate
    status
    updatedAt
    uuid
    deliveryTrackingId
  }
`;

const orderBasic = gql`
  fragment OrderBasic on Order {
    uuid
    transactionNumber
    firstItem {
      productName
      productPrimaryPhoto
    }
    firstName
    lastName
    grandTotal
    paymentOption {
      uuid
      paymentName
    }
    shippingMethod {
      uuid
      name
    }
    transactionFee
    platformOrigin
  }
`;

const orderFull = gql`
  fragment Order on Order {
    uuid
    bankSlipUrl
    cart {
      cartItemsTotal
    }
    items {
      edges {
        node {
          ...Item
        }
      }
    }
    contactNumber
    contactDialCode
    currency
    deliveryAddress
    deliveryAddressLine1
    deliveryAddressLine2
    deliveryBuilding
    deliveryRoom
    deliveryFloor
    deliveryBarangay
    deliveryFee
    email
    firstName
    lastName
    orderStatus {
      ...OrderStatusFull
    }
    pageRecipient {
      ...PageRecipientBasic
    }
    paymentOption {
      paymentName
      paymentType
      uuid
    }
    promoCode {
      ...PromoCode
    }
    remarks
    nearestLandmark
    shippingMethod {
      ...ShippingMethod
    }
    tax
    subtotal
    total
    grandTotal
    platformOrigin
    transactionFee
    paymentProviderFee
    changeFor
    transactionNumber
    createdAt
    courierOrderId
    courierRiderName
    courierRiderMobileNumber
    courierDeliveryStatusToMessage
    courierDeliveryStatus
    buyoutAdditionalFee

    requiredKyc
    kycLevel
    completedKycAt
    kycApprovedAt
    idCardType
    proofOfBillingType
    proofOfIncomeType
    identityPhotoUrl
    idCardUrl
    proofOfBillingPhotoUrl
    proofOfIncomePhotoUrl
    idNumber
    existingKycRefNo
    kycDetails {
      ...KycDetail
    }
    advanceOrder
    advanceOrderDate

    requireMotobox
    motoboxFee
    plateNumber
    carColor
    landmark
    carModel
    thirdPartyPaymentReferenceId
  }
  ${Product.fragments.cartItem}
  ${orderStatusFull}
  ${CustomerInfo.fragments.pageRecipientBasic}
  ${Product.fragments.promoCode}
  ${Shipping.fragments.shippingMethod}
  ${CustomerInfo.fragments.kycDetails}
`;

const orderStatus = gql`
  fragment OrderStatus on OrderStatus {
    uuid
    placedAt
    status
    order {
      ...OrderBasic
    }
    shipOutDate
    estimateArriveDate
  }
  ${orderBasic}
`;

export const Order = {
  fragments: { orderBasic, orderStatus, orderFull },
};
