import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import JWT from "../utils/helpers/jwt";
import CurrentUserModule from "../store/modules/CurrentUserModule";
import { createProvider } from "@/plugins/vue-apollo";

@Component
export default class AuthMixin extends Vue {
  public get currentJwt() {
    return new JWT();
  }

  public onLogOut() {
    this.currentJwt.removeToken();
    this.$router.push("/login");
  }

  public get currentUserModule() {
    return getModule(CurrentUserModule, this.$store);
  }

  public get me() {
    return this.currentUserModule.me;
  }

  public resetClient() {
    const client = createProvider(this.currentJwt.token).defaultClient;
    this.$apolloProvider.defaultClient = client;
  }
}
