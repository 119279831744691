
import { Mixins, Component } from "vue-property-decorator";
import SalesPartnerBillingsExport from "@/components/SalesPartnerBillingsExport";
import BaseCsvExporter from "@/components/BaseCsvExporter.vue";
import NotifierMixin from "@/mixins/NotifierMixin";
import moment from "moment-mini";

@Component({
  components: {
    SalesPartnerBillingsExport,
    BaseCsvExporter,
  },
})
export default class SalesPartnerBillingExport extends Mixins(NotifierMixin) {
  private get labels() {
    const labels = {
      billingNumber: "Reference Number",
      merchant: "Merchant Name",
      billingPeriod: "Billing Period",
      billingDueDate: "Billing Due Date",
      amount: "Amount",
      status: "Status",
    };

    return labels;
  }

  private get fields() {
    return Object.keys(this.labels);
  }

  private get csvName() {
    const timestamp = moment().unix();
    return `${timestamp}_sales_partner_billings`;
  }

  private alertError(billings: []) {
    if (!billings || billings.length === 0) {
      this.showErrorAlert("No data to export");
    }
  }
}
