import gql from "graphql-tag";

const salesPartner = gql`
  fragment SalesPartner on User {
    uuid
    fullName
    affiliateCode
    role
    referredMiniAppsCount
    resellerTotalBillings
  }
`;

export const SalesPartner = {
  fragments: { salesPartner },
};
