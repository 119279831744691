
import { Component, Mixins, Prop } from "vue-property-decorator";
import BreakpointMixin from "@/mixins/BreakpointMixin";

@Component({
  computed: {
    totalConvenienceFee() {
      return this.ordersBaseOnConvenienceFee.length * 10;
    },
  },
})
export default class ConvenienceFeeDetails extends Mixins(BreakpointMixin) {
  @Prop({ type: String, default: "" })
  private billiPeriod!: string;

  @Prop({ type: Array, default: () => [] })
  private ordersBaseOnConvenienceFee!: string[];

  private formatString(input: string): string {
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  data() {
    return {
      showOrders: false,
      headers: [
        {
          text: "Order No.",
          align: "left",
          sortable: false,
          value: "orderNo",
        },
        {
          text: "Order Status",
          align: "center",
          sortable: false,
          value: "orderStatus",
        },
        {
          text: "Convenience Fee",
          align: "right",
          sortable: false,
          value: "convenienceFee",
        },
      ],
    };
  }
}
