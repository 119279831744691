
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import WindowSizeListenerMixin from "@/mixins/WindowSizeListenerMixin";

@Component({})
export default class RadioBoxGroup extends Mixins(WindowSizeListenerMixin) {
  @Prop({ type: Array, default: () => [] })
  private options!: { value: string; index: number }[];

  @Prop({ type: Number })
  private preSelect?: number;

  private selectedOption = this.options[0].index;

  @Watch("preSelect")
  private onPreselectChange() {
    if (this.preSelect) {
      this.selectedOption = this.preSelect;
    }
  }

  @Watch("options")
  private onOptionsChange() {
    this.selectedOption = this.options[0].index;
    this.$emit("changeOption", this.selectedOption);
  }

  @Watch("selectedOption")
  private onSelectedOptionChange() {
    this.$emit("changeOption", this.selectedOption);
  }

  private get width() {
    if (this.window.width < 1025) {
      return this.window.width * 0.35;
    }
    return 185;
  }

  private mounted() {
    this.selectedOption = this.preSelect;
  }
}
