
// Core
import { Component, Mixins } from "vue-property-decorator";
// Extends
import PaginatorMixin from "@/mixins/PaginatorMixin";
// Children
import AutoreplyPrompt from "@/components/GenAI/AutoreplyPrompt.vue";
import BasePrompt from "@/components/GenAI/BasePrompt.vue";
import CloseIcon from "@/components/CloseIcon.vue";
import DescriptionPrompt from "@/components/GenAI/DescriptionPrompt.vue";
import ErrorPrompt from "@/components/GenAI/ErrorPrompt.vue";
import PageTabs from "@/components/PageTabs.vue";
import StoreLaunchPrompt from "@/components/GenAI/StoreLaunchPrompt.vue";
// Models / Helpers
import {
  InheritedPromptTypes,
  ExtendedPromptTypes,
  PromptName,
} from "@/@types/enums/Prompt";
// Queries / Mutations
import promptsQuery from "@/api/queries/prompt-summaries.gql";

@Component({
  components: {
    AutoreplyPrompt,
    BasePrompt,
    DescriptionPrompt,
    CloseIcon,
    ErrorPrompt,
    PageTabs,
    StoreLaunchPrompt,
  },
  methods: {
    getGenerationType(base) {
      const key =
        ExtendedPromptTypes[base.extendedPrompt?.__typename] ||
        InheritedPromptTypes[base.type];

      if (key) {
        return PromptName[key];
      }

      return "Unknown";
    },
    getPromptUUID(base) {
      if (base.type || base.error) {
        return base.uuid;
      }
      return base.extendedPrompt.uuid;
    },
    getTokensAndCost(tokens, price) {
      if (!tokens || !price) {
        return;
      }

      return `${tokens} ($${price.toFixed(4)})`;
    },
    getPromptDuration(time) {
      let remaining = Math.floor(time / 1000);
      // Seconds
      const sec = remaining % 60;
      if (remaining === 0) {
        return `${sec}s`;
      }

      remaining = Math.floor(remaining / 60);
      // Minutes
      const min = remaining % 60;
      if (remaining === 0) {
        return `${min}m ${sec}s`;
      }

      // Hours (unlikely to be needed)
      remaining = Math.floor(remaining / 60);

      return `${remaining}h ${min}m ${sec}s`;
    },
  },
  apollo: {
    promptsQuery: {
      query: promptsQuery,
      variables() {
        return {
          first: this.limit,
          last: null,
          after: this.startCursor,
          ...this.queryFilter,
        };
      },
      update({ fetchPrompts }) {
        this.prompts = fetchPrompts.edges.map((edge) => {
          const createdDate = new Date(edge.node.createdAt);
          const sentDate = new Date(edge.node.sentAt);
          const receivedDate = new Date(edge.node.receivedAt);
          const blank = "- - -";
          const isSuccess = !edge.node.error;

          // Return the prompt summary
          return {
            merchant: edge.node.app.name,
            status: isSuccess ? "success" : "error",
            date: createdDate.toDateString(),
            sent: edge.node.sentAt ? sentDate.toLocaleTimeString() : blank,
            duration: edge.node.receivedAt
              ? this.getPromptDuration(receivedDate - sentDate)
              : blank,
            type: this.getGenerationType(edge.node),
            promptTokens:
              this.getTokensAndCost(
                edge.node.promptTokens,
                edge.node.promptPrice
              ) || blank,
            completionTokens:
              this.getTokensAndCost(
                edge.node.completionTokens,
                edge.node.completionPrice
              ) || blank,
            action: isSuccess ? edge.node.action : blank,
            prompt: {
              uuid: this.getPromptUUID(edge.node),
              type: isSuccess ? this.getGenerationType(edge.node) : "Error",
            },
          };
        });
        this.pageInfo = fetchPrompts.pageInfo;
        this.totalCount = fetchPrompts.totalCount;
      },
    },
  },
})
export default class GenAIIndex extends Mixins(PaginatorMixin) {
  // Sources
  private tabs = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Manual Generations",
      value: "manual",
    },
    {
      label: "Automated Generations",
      value: "automated",
    },
  ];

  private baseQueryFilter = {
    appUuid: null,
    keyword: null,
    isAutomated: null,
    sortBy: "created_at",
    sortDirection: "desc",
  };

  // Data
  private queryFilter = { ...this.baseQueryFilter };

  private prompts = [];

  private selectedPrompt = null;

  // States
  private showDetailsMobile = false;

  private selectedTab = "all";

  public limit = 10;

  private get headers() {
    const commonProps = {
      align: "center",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4",
    };
    if (this.$vuetify.breakpoint.smAndUp) {
      commonProps.class += " py-4";
    }

    return [
      {
        ...commonProps,
        text: "MERCHANT NAME",
        align: "start",
        value: "merchant",
      },
      {
        ...commonProps,
        text: "STATUS",
        value: "status",
      },
      {
        ...commonProps,
        text: "PROMPT DATE",
        value: "date",
      },
      {
        ...commonProps,
        text: "PROMPT TIME",
        value: "sent",
      },
      {
        ...commonProps,
        text: "PROMPT DURATION",
        value: "duration",
      },
      {
        ...commonProps,
        text: "GENERATION TYPE",
        value: "type",
      },
      {
        ...commonProps,
        text: "INPUT TOKENS",
        value: "promptTokens",
      },
      {
        ...commonProps,
        text: "OUTPUT TOKENS",
        value: "completionTokens",
      },
      {
        ...commonProps,
        text: "ACTION",
        value: "action",
      },
    ];
  }

  private cellClass(item, header) {
    switch (header) {
      case "action":
        return [item[header], "text-capitalize"];
      case "status":
        return [`prompt-${item.status}`, "text-capitalize"];
      default:
        return null;
    }
  }

  private cellStyle(item, header) {
    switch (header) {
      case "merchant":
        return "margin-right: 34px";
      default:
        return null;
    }
  }

  private itemClass({ prompt: { uuid } }: { prompt: { uuid: string } }) {
    const activeClass =
      uuid === this.selectedPrompt?.uuid
        ? "grey lighten-4 font-weight-medium primary--text"
        : "";
    return `cursor-pointer ${activeClass}`;
  }

  private get hasSelectedPrompt() {
    return this.selectedPrompt !== null;
  }

  private get promptComponent() {
    if (!this.selectedPrompt) return null;

    switch (this.selectedPrompt.type) {
      case PromptName.productDescription:
        return "description-prompt";
      case PromptName.commentAutoreply:
        return "autoreply-prompt";
      case PromptName.storeLaunch:
        return "store-launch-prompt";
      case PromptName.greeting:
      case PromptName.welcomeMessage:
        return "base-prompt";
      default:
        return "error-prompt";
    }
  }

  private selectTab(tab) {
    switch (tab) {
      case "manual":
        this.queryFilter.isAutomated = false;
        break;
      case "automated":
        this.queryFilter.isAutomated = true;
        break;
      default:
        this.resetFilters();
    }

    this.$refs.baseViewManager.resetFirstPage();
    this.$apollo.queries.promptsQuery.refetch();
  }

  private resetFilters() {
    this.queryFilter = { ...this.baseQueryFilter };
  }

  private setPrompt({ prompt }: any) {
    this.selectedPrompt = prompt;
  }
}
