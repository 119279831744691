import Vue from "vue";
import Vuex from "vuex";
import CurrentUserModule from "./modules/CurrentUserModule";
import NotifierModule from "./modules/NotifierModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { CurrentUserModule, NotifierModule },
});
