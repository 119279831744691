import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import Bugsnag from "@/plugins/bugsnag";
import i18n from "@/plugins/i18n"; // path to i18n export
import Vuelidate from "vuelidate";
import { createProvider } from "@/plugins/vue-apollo";
import { importComponents } from "./utils/global-components.config";
import "../src/styles/app.scss";

import PageMixin from "./mixins/PageMixin";

// Initialize Bugsnag
const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue?.installVueErrorHandler(Vue);

Vue.config.productionTip = false;
Vue.use(Vuelidate);

// imports Base*.vue components
importComponents(Vue);
Vue.mixin(PageMixin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
