
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import NotifierModule from "./store/modules/NotifierModule";

@Component
export default class App extends Vue {
  public get notifierModule() {
    return getModule(NotifierModule, this.$store);
  }
}
