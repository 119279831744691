
import { Component, Mixins, Watch } from "vue-property-decorator";
import moment from "moment-mini";
import TopNav from "../components/TopNav.vue";
import SideNav from "../components/SideNav.vue";
import ReloginDialog from "../components/ReloginDialog.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
import JWT from "../utils/helpers/jwt";
import Me from "../api/queries/me.gql";
import { Nullable } from "../@types/BaseTypes";
import { CurrentUser } from "../@types/Auth";
import BreakpointMixin from "../mixins/BreakpointMixin";
import AuthMixin from "../mixins/AuthMixin";

@Component({
  apollo: {
    currentUser: {
      query: Me,
      update({ me }: { me: CurrentUser }) {
        this.currentUserModule.setCurrentUser(me);

        return me;
      },
    },
  },
  components: {
    TopNav,
    SideNav,
    ReloginDialog,
    BreadCrumb,
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    const currentToken = new JWT();

    if (!currentToken.isValid) {
      next("/login");
    } else {
      next();
    }
  },
})
export default class Authenticated extends Mixins(BreakpointMixin, AuthMixin) {
  private toggleDrawer = true;

  private toggleReloginDialog = false;

  private unixTimestamp = 0;

  private currentUser: Nullable<CurrentUser> = null;

  // For security: Added this observable in order to prevent unexpected errors
  // whenever we execute mutations and queries. We will need to force user to
  // relogin credentials; token expires within 8hrs
  @Watch("unixTimestamp")
  private unixTimestampObserver() {
    if (!this.currentJwt.isValid) {
      this.toggleReloginDialog = true;
    }
  }

  private initUnixTimestamp() {
    const nSeconds = 1000;

    window.setInterval(() => {
      this.unixTimestamp = moment().unix();
    }, nSeconds);
  }

  private mounted() {
    this.initUnixTimestamp();
    this.toggleDrawer =
      this.breakpoint.mdAndUp && this.breakpoint.width >= 1500;
  }
}
