
import { Vue, Component, Prop } from "vue-property-decorator";
import { CartItem } from "@/@types/Cart";
import { CartItemModel } from "@/models/CartItemModel";

@Component
export default class OrderDetailsItemList extends Vue {
  @Prop({ type: Array, default: () => [] })
  private items!: CartItem[];

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  private collapsed = false;

  private limit = 3;

  private get limitedItems() {
    return this.items.filter((v: CartItem, k: number) => k + 1 <= this.limit);
  }

  private get limitedOrAllItems() {
    return !this.collapsed ? this.limitedItems : this.items;
  }

  private get itemInstances() {
    return this.limitedOrAllItems.map(
      (item: CartItem) => new CartItemModel(item)
    );
  }
}
