
import { Nullable } from "@/@types/BaseTypes";
import { PaymentGateway, PaymentOptionDetail } from "@/@types/Payment";
import FormMixin from "@/mixins/FormMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import {
  paymentOptionCreate,
  paymentOptionUpdate,
  thirdPartyApiSettingOptionUpdate,
} from "@/api/mutations/merchant";
import { PaymentTypes } from "@/@types/enums/Payment";

@Component({
  validations: {
    displayName: { required },
    midProvider: { required },
    paymentDetails: {
      $each: {
        content: { required },
      },
    },
  },
})
export default class PaymentOptionFormThirdParty extends Mixins(
  LoaderMixin,
  NotifierMixin,
  FormMixin
) {
  @Prop({ type: String, default: "" })
  private value!: string;

  @Prop({ type: Object, default: null })
  private paymentMethod!: Nullable<PaymentGateway>;

  @Prop({ type: String, default: "" })
  private title!: string;

  private displayName = "";

  private midProvider: Nullable<string> = null;

  private defaultSelected = true;

  // { optionDetailUuid, content }
  private paymentDetails: PaymentOptionDetail[] = [];

  private get midProviderOptions() {
    return [
      { text: "Default MID Provider", value: "default" },
      { text: "Paymongo", value: "paymongo" },
    ];
  }

  private get providerLabel() {
    return this.isCreditCard ? "Credit Card Provider" : "API Provider";
  }

  private get isCreditCard() {
    return (
      this.paymentMethod &&
      this.paymentMethod?.paymentOptionType === PaymentTypes.creditCard
    );
  }

  private get isThirdParty() {
    return (
      this.paymentMethod &&
      this.paymentMethod?.paymentOptionType === PaymentTypes.thirdParty
    );
  }

  private get creditCardMidProvider() {
    return this.midProvider === "default" && this.isCreditCard
      ? this.midProviderOptions[1].value
      : this.midProvider;
  }

  private get thirdPartyProvider() {
    return this.midProvider === "default" && this.isThirdParty
      ? this.displayName.toLowerCase()
      : this.midProvider;
  }

  private onDefaultSelected(value: string) {
    if (this.isCreditCard && !/default/.test(value)) {
      this.defaultSelected = false;
    }
  }

  private appendPaymentDetail() {
    this.paymentDetails.push({ content: "" });
  }

  private thirdPartyOptionUpdate(optionUuid: Nullable<string> = null) {
    if (optionUuid) {
      return this.$apollo
        .mutate({
          mutation: thirdPartyApiSettingOptionUpdate,
          variables: {
            input: { optionUuid, provider: this.midProvider },
          },
        })
        .catch((e) => Promise.reject(e));
    }
  }

  private create() {
    const thirdPartyProvider = this.isThirdParty
      ? this.thirdPartyProvider?.toLowerCase()
      : null;
    const creditCardProvider =
      this.isCreditCard && this.creditCardMidProvider
        ? this.creditCardMidProvider
        : null;

    return this.$apollo.mutate({
      mutation: paymentOptionCreate,
      variables: {
        input: {
          paymentGatewayUuid: this.paymentMethod?.uuid,
          details: this.paymentDetails,
          appUuid: this.$route.params.merchantUuid,
          paymentName: this.displayName,
          thirdPartyProvider,
          creditCardProvider,
        },
      },
    });
  }

  private update() {
    return this.$apollo.mutate({
      mutation: paymentOptionUpdate,
      variables: {
        input: {
          details: this.paymentDetails,
          optionUuid: this.paymentMethod?.appPaymentOption?.uuid,
          paymentName: this.displayName,
        },
      },
    });
  }

  private onSubmit() {
    this.$v.$touch();

    console.log("test");

    if (!this.$v.$invalid) {
      this.loadingInProgress();
      const mutation = !this.paymentMethod?.appPaymentOption
        ? this.create
        : this.update;

      mutation()
        .then(async ({ data }: any) => {
          let uuid =
            (this.paymentMethod?.appPaymentOption &&
              this.paymentMethod.appPaymentOption.uuid) ||
            null;
          if (!this.defaultSelected && data.paymentOptionCreate) {
            uuid = data.paymentOptionCreate.option.uuid;
          }

          await this.thirdPartyOptionUpdate(uuid);
        })
        .then(() => {
          this.loadingDone();
          this.$emit("form:success", "Payment Setting updated.");
          this.$emit("dialog:close");
        })
        .catch((e: any) => {
          this.loadingDone();
          this.$emit("form:error", e.message);
          this.$emit("dialog:close");
        });
    }
  }

  private removePaymentDetail(idx: number) {
    this.paymentDetails = this.paymentDetails.filter(
      (_value: PaymentOptionDetail, key: number) => key !== idx
    );
  }

  private mounted() {
    this.displayName = (this.paymentMethod && this.paymentMethod.title) || "";
    this.midProvider = "default";

    if (this.paymentMethod?.appPaymentOption) {
      const option = this.paymentMethod?.appPaymentOption;

      this.paymentDetails = option.optionDetails.map((optionDetail: any) => ({
        content: optionDetail.content,
      }));

      if (this.isCreditCard || this.isThirdParty) {
        this.midProvider = !option.thirdPartyApiSetting
          ? "default"
          : "paymongo";
      }
    }

    if (!this.paymentDetails.length) {
      this.appendPaymentDetail();
    }
  }
}
