
import { Component, Mixins, Prop } from "vue-property-decorator";
import BreakpointMixin from "../mixins/BreakpointMixin";

@Component
export default class SideNav extends Mixins(BreakpointMixin) {
  @Prop({ type: Boolean, default: false })
  private drawer!: boolean;

  private linkActive(routeName: string) {
    return this.$route.path.includes(routeName);
  }

  private routeTo(name: string) {
    let query = { tab: "activated", tabTitle: "Active" };

    if (name === "merchants") {
      return { name, query };
    } else if (name === "orders") {
      query = { tab: "pending", tabTitle: "Pending" };
      return { name, query };
    }
    return { name };
  }

  private get width() {
    return this.breakpoint.smAndDown ? "250px" : "300px";
  }

  private get iconHeight() {
    return this.breakpoint.smAndDown ? "15" : "20";
  }

  private items = [
    {
      title: "Overview",
      icon: require("@/assets/side_nav/overview.svg"),
      routeName: "overview",
    },
    {
      title: "Orders",
      icon: require("@/assets/side_nav/orders.svg"),
      routeName: "orders",
    },
    {
      title: "Merchants",
      icon: require("@/assets/side_nav/merchants.svg"),
      routeName: "merchants",
    },
    {
      title: "Sales Partner",
      icon: require("@/assets/side_nav/sales-partner.svg"),
      routeName: "sales-partners",
    },
    {
      title: "J&T Accounts",
      icon: require("@/assets/side_nav/jnt.svg"),
      routeName: "jnt-accounts",
    },
    {
      title: "Gen AI",
      icon: require("@/assets/icons/cg-orange.svg"),
      routeName: "gen-ai",
    },
    {
      title: "Announcements",
      icon: require("@/assets/side_nav/announcements.svg"),
      routeName: "announcements",
    },
    {
      title: "Sales Channel Requests",
      icon: require("@/assets/side_nav/sales-channel-requests.svg"),
      routeName: "sales-channel-requests",
    },
    {
      title: "Billing Manager",
      icon: require("@/assets/side_nav/billing.svg"),
      routeName: "billing-manager",
    },
    {
      title: "Settings",
      icon: require("@/assets/side_nav/settings.svg"),
      routeName: "settings",
    },
  ];
}
