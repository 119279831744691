import { CURRENCY } from "../constants";

export const currency = (money: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: CURRENCY,
  })
    .format(money)
    .replace(/^(\D+)/, "$1 ");
};

export const numeral = (num: number) => {
  return new Intl.NumberFormat("en-US").format(num);
};
