export enum BundleTypes {
  all = "",
  b2c = "Ecommerce B2C Bundle",
  shopify = "Shopify Bundle",
  multiStore = "Mall Stores Management Bundle",
  inquiry = "Inquiry Bundle",
  distributor = "Distributor Bundle",
  insurance = "Insurance Bundle",
  affiliate = "Affiliate Bundle",
}
