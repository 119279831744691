import { shortDateOne } from "@/utils/helpers/date-formatter";
import { currency } from "@/utils/helpers/numeric-formatter";
import { startCase } from "lodash-core";
import Vue from "vue";
import { salesPartnerBillings } from "../api/queries/sales-partners";

export default Vue.extend({
  apollo: {
    billings: {
      query: salesPartnerBillings,
      variables() {
        const self = this as any;
        return {
          userUuid: self.salesPartnerUuid(),
        };
      },
      update(data: any) {
        const billings = data.referralsBillings.edges;

        const billingsNode = (billing: { node: any }) => {
          return billing.node;
        };

        return billings.map(billingsNode);
      },
    },
  },
  data() {
    return {
      billings: [],
    };
  },
  computed: {
    salesPartnerUuid(): string {
      return this.$route.params.salesPartnerUuid;
    },
    dataForCsv(): any {
      const self = this as any;

      if (self.billings.length >= 1) {
        return self.billings.map((billing: any) => {
          return {
            billingNumber: billing.referenceNumber,
            merchant: billing.app.name,
            billingPeriod: `${shortDateOne(
              billing.billingPeriodStartAt
            )} - ${shortDateOne(billing.billingPeriodEndAt)}`,
            billingDueDate: shortDateOne(billing.billingPeriodDueAt),
            amount: currency(billing.currentAmount),
            status: startCase(billing.status),
          };
        });
      }

      return [];
    },
  },
  render(): any {
    if (this.$scopedSlots.default) {
      const self = this as any;

      return this.$scopedSlots.default({
        billings: self.dataForCsv,
      });
    }
  },
});
