
import { Mixins, Component } from "vue-property-decorator";
import FormMixin from "@/mixins/FormMixin";
import { email, required } from "vuelidate/lib/validators";
import TopNav from "../components/TopNav.vue";

@Component({
  validations: {
    email: { email, required },
  },
  components: { TopNav },
})
export default class ForgotPassword extends Mixins(FormMixin) {
  private email = "";

  private sendResetLink() {
    if (!this.$v.$invalid) {
      // TODO: bind mutation here
    }
  }
}
