import gql from "graphql-tag";

const kycDetails = gql`
  fragment KycDetail on KycDetail {
    uuid
    salutation
    middleName
    birthdate
    mothersMaidenName
    nationality
    grossMonthlyIncome
    contactPerson
    contactPersonNumber
    contactPersonRelationship
    sssNo
    gsisNo
    tinNo
    billMedia
    agreedToKycTerms
    billingEmail
    billingAddress
    passportIdNo
    passportExpiry
    employmentType
  }
`;

const pageRecipientBasic = gql`
  fragment PageRecipientBasic on PageRecipient {
    uuid
    name
    profilePic
  }
`;

export const CustomerInfo = {
  fragments: { kycDetails, pageRecipientBasic },
};
