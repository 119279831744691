
import { Component, Mixins, Prop } from "vue-property-decorator";
import moment from "moment-mini";
import { SHORT_DATE_FORMAT } from "../utils/constants";
import { Plans } from "../@types/enums/Billing";
import { BundleTypes } from "../@types/enums/Merchant";
import { PageStatus } from "../@types/enums/Page";
import { startCase } from "lodash-core";
import { merchants } from "../api/queries/merchants";
import { currency, numeral } from "@/utils/helpers/numeric-formatter";
import { fullDateTime } from "@/utils/helpers/date-formatter";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import PaginatorMixin from "@/mixins/PaginatorMixin";

@Component({
  apollo: {
    merchants: {
      query: merchants,
      fetchPolicy: "cache-and-network",
      variables() {
        return { ...this.queryVariables() };
      },
      update({ adminApps }: any) {
        this.totalCount = adminApps.totalCount;
        this.pageInfo = adminApps.pageInfo;

        return adminApps.edges.map(({ node }: any) => {
          let plan =
            (node.subscribedEnterprisePlan &&
              node.subscribedEnterprisePlan.name) ||
            node.plan;

          plan = /MSME/.test(plan) ? "MSME" : startCase(plan.toLowerCase());

          return {
            ...node,
            plan: this.displayFreeStarter(plan),
            accumulatedConvenienceFee: currency(node.accumulatedConvenienceFee),
            salesChannels: this.getActiveChannels(node.activeSalesChannel),
            accumulatedCompletedOrders: numeral(
              node.accumulatedCompletedOrders
            ),
            launchedAt: fullDateTime(node.launchedAt),
            actions: node.uuid,
            status: node.status,
            merchantName: [node.name, node.activePagePhotoUrl],
            bundleName: this.parseBundleName(node.serviceBundle.name),
            active: node.active,
          };
        });
      },
    },
  },
})
export default class MerchantsIndex extends Mixins(
  BreakpointMixin,
  PaginatorMixin
) {
  @Prop({ type: String, default: "activated" })
  private tab!: string;

  private merchants = [];

  private plan = null;

  private rangeFrom = moment().startOf("year").format(SHORT_DATE_FORMAT);

  private rangeTo = moment().format(SHORT_DATE_FORMAT);

  private keywords = "";

  private bundle = null;

  private parseBundleName(name: string) {
    return name === "Mall Stores Management Bundle"
      ? "Multi-Store Bundle"
      : name;
  }

  private get bundleOptions() {
    return Object.values(BundleTypes).map((value: string) => {
      let text = value === "" ? "All" : value;
      if (value === "Mall Stores Management Bundle") {
        text = "Multi-Store Bundle";
      }
      const parseValue = value === "" ? null : value;
      return { value: parseValue, text };
    });
  }

  private get planOptions() {
    return Object.values(Plans).map((value: string) => {
      let text = startCase(value.replace(/pro_/, ""));
      let paramValue = value === "" ? null : value;
      if (/^pro_msme/.test(value)) {
        text = text.toUpperCase();
      } else if (value === "") {
        text = "All";
      }

      return { value: paramValue, text };
    });
  }

  private get tabTitle() {
    return `${this.$route.query.tabTitle} Merchants`;
  }

  private get salesChannels() {
    return [
      "messenger-overview.svg",
      "GLIFE.png",
      "instagram-overview.svg",
      "viber-overview.svg",
      "paymaya-overview.svg",
    ].map((img: string) => require(`@/assets/${img}`));
  }

  private queryVariables() {
    const defaultParams = { first: this.limit, after: this.startCursor };

    if (!this.keywords) {
      return {
        ...defaultParams,
        status: this.tab,
        keywords: this.keywords,
        planType: this.plan,
        startDate: this.rangeFrom,
        endDate: this.rangeTo,
        bundleName: this.bundle,
      };
    }

    // forced other inputs to null to prevent overlapping of passed types
    return {
      ...defaultParams,
      status: this.tab,
      planType: null,
      startDate: null,
      endDate: null,
      keywords: this.keywords,
    };
  }

  private get headers() {
    const commonProps = {
      align: "center",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4 py-4",
    };

    return [
      {
        ...commonProps,
        text: "Merchant Name",
        align: "start",
        width: "15%",
        value: "merchantName",
      },
      {
        ...commonProps,
        text: "Bundle",
        value: "bundleName",
      },
      {
        ...commonProps,
        text: "Merchant ID",
        value: "activePage.fbPageId",
      },
      {
        ...commonProps,
        text: "Plan Type",
        value: "plan",
      },
      {
        ...commonProps,
        text: "Active Sales Channels",
        width: "10%",
        value: "salesChannels",
      },
      {
        ...commonProps,
        text: "Date Launched",
        value: "launchedAt",
      },
      {
        ...commonProps,
        text: "Store Owner",
        align: "start",
        value: "ownerFullName",
      },
      {
        ...commonProps,
        text: "Total Orders",
        value: "accumulatedCompletedOrders",
      },
      {
        ...commonProps,
        text: "Accumulated Convenience \n Fees",
        width: "10%",
        value: "accumulatedConvenienceFee",
      },
      {
        ...commonProps,
        text: "Status",
        value: "status",
      },
      {
        ...commonProps,
        text: "",
        value: "actions",
      },
    ];
  }

  private pageStatuses() {
    return Object.entries(PageStatus).map(([key, value]: [string, string]) => ({
      value,
      label: startCase(key),
    }));
  }

  private isActive(status: string) {
    return status === PageStatus.active;
  }

  private statusLabel(status: string) {
    return this.isActive(status) ? "Active" : "Inactive";
  }

  private getActiveChannels(activeSalesChannel: string[]) { 
    const images = [
      { channel: "messenger", image: "messenger-overview.svg" },
      { channel: "glife", image: "GLIFE.png" },
      { channel: "instagram", image: "instagram-overview.svg" },
      { channel: "viber", image: "viber-overview.svg" },
      { channel: "paymaya", image: "paymaya-overview.svg" },
    ]; 

    const channels = activeSalesChannel.length > 0 ? activeSalesChannel : ["messenger"];

    return images
      .filter(image => channels.includes(image.channel))
      .map(image => require(`@/assets/${image.image}`));
  }

  private displayFreeStarter(plan: string) {
    return plan === "Free" ? "Free Starter" : plan
  }
}
