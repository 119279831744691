
import { Component, Mixins, Watch } from "vue-property-decorator";
import { shortDateOne, fullDate } from "@/utils/helpers/date-formatters";
import { currency } from "@/utils/helpers/numeric-formatter";
import { startCase, snakeCase } from "lodash-core";
import { Nullable } from "@/@types/BaseTypes";
import { BillingFull } from "@/@types/Billing";
import {
  billingApprove,
  billingReject,
  billingCreate,
  billingExport,
} from "@/api/mutations/billing";
import { merchantNames } from "@/api/queries/merchants";
import { allBillings as query } from "@/api/queries/all-billings";
import NotifierMixin from "@/mixins/NotifierMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import PaginatorMixin from "@/mixins/PaginatorMixin";
import BreakpointMixin from "@/mixins/BreakpointMixin";
import _ from "lodash"; // Import lodash

@Component({
  components: {
    ImagePreviewDialog: () => import("@/components/ImagePreviewDialog.vue"),
  },
  apollo: {
    billings: {
      query,
      variables() {
        return { ...this.queryVariables() };
      },
      update({ allBillings }: any) {
        const { pageInfo } = allBillings;
        this.pageInfo = pageInfo;
        this.totalCount = allBillings.totalCount;

        return allBillings.edges.map(({ node }: { node: BillingFull }) => {
          const plan = this.planGenerator(node);

          return {
            ...node,
            billingPeriod: `${shortDateOne(
              node.billingPeriodStartAt
            )} - ${shortDateOne(node.billingPeriodEndAt)}`,
            depositSlips: [],
            latestDepositSlip: this.latestDepositSlip(node),
            action: node.uuid,
            status: startCase(node.status),
            currentAmount: currency(node.currentAmount),
            billingPeriodDueAt: fullDate(node.billingPeriodDueAt),
            plan: this.displayTieringType(node.tierType),
            loadingState: false,
            appName: node.app.name,
          };
        });
      },
    },
    merchants: {
      query: merchantNames,
      variables() {
        return {};
      },
      update({ adminApps }: any) {
        return adminApps.edges.map(({ node }: any) => {
          return node;
        });
      },
    },
  },
})
export default class MerchantBillings extends Mixins(
  NotifierMixin,
  LoaderMixin,
  PaginatorMixin,
  BreakpointMixin
) {
  private billings: any = [];

  private displayStatus(item) {
    if (item === "For Payment" || item === "Payment Submitted") {
      return "Pending";
    }
    return item;
  }

  private debouncedUpdateSearchAppUuids = _.debounce(
    this.updateSearchAppUuids,
    1500
  );

  private debouncedUpdateSearchMonthFilter = _.debounce(
    this.updateSearchMonthFilter,
    1500
  );

  private debouncedUpdateKeywordsSearch = _.debounce(
    this.updateSearchKeywords,
    1500
  );

  private recreateDebouncedFunctions() {
    this.debouncedUpdateSearchAppUuids.cancel();
    this.debouncedUpdateSearchMonthFilter.cancel();
    this.debouncedUpdateKeywordsSearch.cancel();
    this.debouncedUpdateSearchAppUuids();
    this.debouncedUpdateSearchMonthFilter();
    this.debouncedUpdateKeywordsSearch();
  }

  @Watch("appUuids")
  @Watch("monthFilter")
  @Watch("keywords")
  private onFilterChange() {
    this.recreateDebouncedFunctions();
  }

  private updateSearchAppUuids() {
    this.serachAppUuids = this.appUuids;
  }

  private updateSearchMonthFilter() {
    this.searchMonthFilter = this.monthFilter;
  }

  private updateSearchKeywords() {
    this.keywordsSearch = this.keywords;
  }

  private previewedDepositSlip = null;

  private eventEmitter = "billing-approve";

  private billingUuid: Nullable<string> = null;

  private billingConfirmationMessage = "";

  private billingStatuses = ["Paid", "For Payment", "Payment Submitted"];

  private previousStatus = "";

  private keywords = "";

  private keywordsSearch = "";

  private monthFilter = 0;

  private searchMonthFilter = 0;

  private monthFilterOptions = [
    { text: "All", value: 0 },
    { text: "Last Month", value: 1 },
    { text: "Last 2 Months", value: 2 },
    { text: "Last 3 Months", value: 3 },
    { text: "Last 6 Months", value: 6 },
    { text: "Last 12 Months", value: 12 },
  ];

  private appUuids = [];

  private merchants = [];

  private appsSelectionValue: any = [];

  private searchInput = "";

  private serachAppUuids = [];

  private handleEnter() {
    this.appUuids = [];
    this.monthFilter = 1;
    this.keywords = "";
  }

  private get input() {
    const appUuids = this.appUuids.map(({ value }: any) => {
      return value;
    });
    return {
      appUuids,
    };
  }

  private get appsSelection(): any {
    return this.merchants.map((merchant: any) => {
      return { text: merchant.name, value: merchant.uuid };
    });
  }

  private set appsSelection(value: any) {
    this.appsSelectionValue = value;
  }

  private performAction(action, uuid) {
    if (action === "Paid") {
      this.onDisplayApprovedBillingDialog(uuid);
    } else if (action === "For Payment") {
      this.onDisplayRejectBillingDialog(uuid);
    }
  }

  private planGenerator(node: BillingFull) {
    let plan =
      (node.app.subscribedEnterprisePlan &&
        node.app.subscribedEnterprisePlan.name) ||
      node.app.plan;
    plan = startCase(plan.toLowerCase());

    if (/msme/.test(plan.toLowerCase())) {
      plan = plan.toUpperCase();
    }

    if (node.details.length) {
      plan = startCase(node.details[0].planName.replace("Plan", ""));
    }

    return plan.replace(/pro/gi, "");
  }

  private get isBillingApproveEmitter() {
    return this.eventEmitter === "billing-approve";
  }

  private get billingsLoading() {
    return this.$apollo.queries.billings.loading;
  }

  private queryVariables() {
    const defaultParams = { first: this.limit, after: this.startCursor };
    const appUuids = this.serachAppUuids.map(({ value }: any) => {
      return value;
    });

    // forced other inputs to null to prevent overlapping of passed types
    return {
      ...defaultParams,
      appUuids,
      keywords: this.keywordsSearch,
      monthFilter: this.searchMonthFilter,
    };
  }

  private get headers() {
    const commonProps = {
      align: "start",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4",
    };

    return [
      {
        ...commonProps,
        text: "Merchant",
        value: "appName",
      },
      {
        ...commonProps,
        text: "Billing Number",
        value: "referenceNumber",
      },
      {
        ...commonProps,
        text: "Billing Period",
        value: "billingPeriod",
      },
      {
        ...commonProps,
        text: "Tier Type",
        value: "plan",
      },
      {
        ...commonProps,
        text: "Amount",
        value: "currentAmount",
      },
      {
        ...commonProps,
        text: "Due Date",
        value: "billingPeriodDueAt",
      },
      {
        ...commonProps,
        text: "Payment Status",
        value: "status",
      },
      {
        ...commonProps,
        text: "Deposit Slip",
        value: "latestDepositSlip",
      },
      {
        align: "center",
        sortable: false,
        class: "font-weight-bold text-uppercase grey lighten-4",
        text: "Actions",
        value: "action",
      },
    ];
  }

  private latestDepositSlip(billing: any) {
    const { depositSlips } = billing;

    return depositSlips.length > 0
      ? depositSlips.map(
          ({ originalUrl }: { originalUrl: string }) => originalUrl
        )[0]
      : null;
  }

  private isPaid(status: string) {
    return status.toLowerCase() === "paid";
  }

  private forPayment(status: string) {
    return snakeCase(status.toLowerCase()) === "for_payment";
  }

  private paymentSubmitted(status: string) {
    return snakeCase(status.toLowerCase()) === "payment_submitted";
  }

  private onBillingCreate() {
    this.loadingInProgress();
    const mutation = billingCreate;
    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { appUuid: this.$route.params.merchantUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.previousStatus = "";
          this.showSuccessAlert(`Billing generated`);
          this.loadingDone();
          this.$apollo.queries.billings.refresh();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.previousStatus = "";
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onBillingStatusUpdate() {
    this.loadingInProgress();
    const mutation = this.isBillingApproveEmitter
      ? billingApprove
      : billingReject;
    const message = this.isBillingApproveEmitter ? "Approved" : "Rejected";

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { billingUuid: this.billingUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.showSuccessAlert(`Billing ${message}`);
          this.loadingDone();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onBillingExport() {
    this.loadingInProgress();
    const mutation = billingExport;

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { billingUuid: this.billingUuid, fromAdmin: true },
        },
        update: () => {
          this.billingUuid = null;
          this.previousStatus = "";
          this.showSuccessAlert(`Billing exported`);
          this.loadingDone();
          this.$apollo.queries.billings.refresh();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.previousStatus = "";
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onDisplayApprovedBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be approved. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-approve";
  }

  private onDisplayRejectBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be rejected. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-reject";
  }

  private onDisplayExportBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Billing details will be exported. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-export";
  }

  private revertStatus() {
    const itemToUpdate = this.billings.find(
      (item) => item.uuid === this.billingUuid
    );
    if (itemToUpdate) {
      itemToUpdate.status = this.previousStatus;
    }
    this.billingUuid = null;
    this.previousStatus = "";
  }

  private onDisplayGenerateMonthlyBillingDialog() {
    this.billingUuid = `billing-${new Date().toISOString()}`;
    this.billingConfirmationMessage =
      "Will manually generate merchant billing. Do you wish to continue?";
    this.eventEmitter = "monthly-billing-generate";
  }

  private displayTieringType(tierType: string) {
    if (tierType === "pro_tier") {
      return "Pro Tier";
    } else if (tierType === "enterprise") {
      return "Enterprise";
    } else {
      return "Free Tier";
    }
  }

  private mounted() {
    this.setLimit(10);
  }
}
