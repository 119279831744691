import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoaderMixin extends Vue {
  public loading = false;

  public loadingInProgress() {
    this.loading = true;
  }

  public loadingDone() {
    this.loading = false;
  }
}
