
// Core
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// Extends
// Children
import BaseOverlayLoader from "@/components/BaseOverlayLoader.vue";
// Models / Helpers
// Queries / Mutations

/**
 * Base Prompt side card template
 * @author Jan Canicon <jan@gorated.ph>
 *                     <jancanicon.work@gmail.com>
 * @since Version {1.4.3}
 *
 * Docs: https://gorated.atlassian.net/wiki/spaces/DMDS/pages/1856143582/Nested+Checkbox
 */
@Component({
  components: {
    BaseOverlayLoader,
  },
})
export default class PromptCard extends Vue {
  @Prop({ type: Boolean, default: null })
  private loading!: boolean;
}
