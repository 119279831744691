
import { Nullable } from "@/@types/BaseTypes";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseAlertDialog extends Vue {
  @Prop({ type: String, default: "Alert" })
  private title!: string;

  @Prop({ type: String, default: "" })
  private message!: string;

  @Prop({ type: String, default: null })
  private value!: Nullable<string>;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: String, required: true })
  private emitter!: string;
}
