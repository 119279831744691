
import FormMixin from "@/mixins/FormMixin";
import { DocumentNode } from "graphql";
import { required } from "vuelidate/lib/validators";
import { Component, Mixins, Prop } from "vue-property-decorator";
import LoaderMixin from "@/mixins/LoaderMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import moment from "moment-mini";
import { SHORT_DATE_FORMAT } from "@/utils/constants";
import { merchantNames } from "@/api/queries/merchants";
import { ExportType } from "@/@types/enums/admin/Export";

@Component({
  validations: {
    form: {
      startDate: { required },
      endDate: { required },
    },
  },
  apollo: {
    merchants: {
      query: merchantNames,
      variables() {
        return {};
      },
      update({ adminApps }: any) {
        return adminApps.edges.map(({ node }: any) => {
          return node;
        });
      },
    },
  },
})
export default class ConfirmExportDialog extends Mixins(
  FormMixin,
  LoaderMixin,
  NotifierMixin
) {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ type: Object, default: {} })
  private exportVariables!: object;

  @Prop({ type: Document })
  private mutation!: DocumentNode;

  @Prop({ type: Boolean, default: false })
  private incubation!: boolean;

  @Prop({ type: Boolean, default: false })
  private merchantProducts!: boolean;

  @Prop({ type: Boolean, default: false })
  private orderExports!: boolean;

  @Prop({ type: Boolean, default: false })
  private loader!: boolean;

  private form = {
    startDate: moment().format(SHORT_DATE_FORMAT),
    endDate: moment().format(SHORT_DATE_FORMAT),
    appUuids: [],
    weekly: false,
  };

  private merchants = [];

  private specific = false;

  private productRow = false;

  private weekly = false;

  private appUuids = [];

  private get merchantDetails(): boolean {
    return this.exportVariables.exportFile === ExportType.merchantDetails;
  }

  private get appsSelection(): any {
    return this.merchants.map((merchant: any) => {
      return { text: merchant.name, value: merchant.uuid };
    });
  }

  private get input() {
    const appUuids = this.appUuids.map(({ value }: any) => {
      return value;
    });
    return {
      ...this.exportVariables,
      startDate: moment(this.form.startDate).toISOString(true),
      endDate: moment(this.form.endDate).toISOString(true),
      appUuids,
      productRow: this.productRow,
      weekly: this.weekly,
    };
  }

  private onCancel() {
    this.$emit("input", false);
    this.$v.$reset();
    // needed to bind default values in datetime picker in order to prevent
    // mutation errors
    this.form = {
      startDate: moment().format(SHORT_DATE_FORMAT),
      endDate: moment().format(SHORT_DATE_FORMAT),
      appUuids: this.appUuids,
    };
  }

  private onConfirm() {
    this.$v.$touch();

    const valid = this.specific ? this.input.appUuids.length > 0 : true;
    if (!this.$v.$invalid && valid) {
      this.loadingInProgress();

      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: {
            input: this.input,
          },
        })
        .then(() => {
          this.loadingDone();
          this.onCancel();

          this.$emit("loader");
          this.$emit("notify:success", "Exporting File");
        })
        .catch((e: any) => {
          this.loadingDone();
          this.onCancel();
          this.$emit("notify:error", e.message);
        });
    } else {
      this.$emit("notify:error", "Invalid Input");
    }
  }
}
