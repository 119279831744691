
import { Component, Prop, Mixins } from "vue-property-decorator";
import { merchantBillings as query } from "../api/queries/merchants";
import { shortDateOne, fullDate } from "../utils/helpers/date-formatters";
import { currency } from "../utils/helpers/numeric-formatter";
import { BundleTypes } from "../@types/enums/Merchant";
import { startCase, snakeCase } from "lodash-core";
import { Nullable } from "@/@types/BaseTypes";
import { PageInfo } from "@/@types/Page";
import { BillingFull } from "@/@types/Billing";
import {
  billingApprove,
  billingReject,
  billingCreate,
} from "@/api/mutations/billing";
import NotifierMixin from "@/mixins/NotifierMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import { MerchantDetails } from "@/@types/Merchant";

@Component({
  components: {
    ImagePreviewDialog: () => import("../components/ImagePreviewDialog.vue"),
  },
  apollo: {
    billings: {
      query,
      fetchPolicy: "cache-and-network",
      variables() {
        return this.queryVariables;
      },
      update({ billings }: any) {
        const { pageInfo } = billings;
        this.$emit("data:page-info", pageInfo);
        this.pageInfo = pageInfo;

        return billings.edges.map(({ node }: { node: BillingFull }) => {
          const plan = this.planGenerator(node);

          return {
            ...node,
            billingPeriod: `${shortDateOne(
              node.billingPeriodStartAt
            )} - ${shortDateOne(node.billingPeriodEndAt)}`,
            depositSlips: [],
            latestDepositSlip: this.latestDepositSlip(node),
            action: node.uuid,
            status: startCase(node.status),
            currentAmount: currency(node.currentAmount),
            billingPeriodDueAt: fullDate(node.billingPeriodDueAt),
            plan: this.displayTieringType(node.tierType),
            loadingState: false,
          };
        });
      },
    },
  },
})
export default class MerchantBillings extends Mixins(
  NotifierMixin,
  LoaderMixin
) {
  @Prop({ type: Number, default: 10 })
  private limit!: number;

  @Prop({ type: String, default: null })
  private startCursor!: Nullable<string>;

  @Prop({ type: Object, default: null })
  private merchant!: Nullable<MerchantDetails>;

  private billings: any = [];

  private pageInfo: Nullable<PageInfo> = null;

  private previewedDepositSlip = null;

  private eventEmitter = "billing-approve";

  private billingUuid: Nullable<string> = null;

  private billingConfirmationMessage = "";

  private planGenerator(node: BillingFull) {
    let plan =
      (node.app.subscribedEnterprisePlan &&
        node.app.subscribedEnterprisePlan.name) ||
      node.app.plan;
    plan = startCase(plan.toLowerCase());

    if (/msme/.test(plan.toLowerCase())) {
      plan = plan.toUpperCase();
    }

    if (node.details.length) {
      plan = startCase(node.details[0].planName.replace("Plan", ""));
    }

    return plan.replace(/pro/gi, "");
  }

  private get isLaunched() {
    return (
      !!this.merchant &&
      !!this.merchant.launchedAt &&
      !!this.merchant.activePage
    );
  }

  private get isBillingApproveEmitter() {
    return this.eventEmitter === "billing-approve";
  }

  private get billingsLoading() {
    return this.$apollo.queries.billings.loading;
  }

  private get queryVariables() {
    return {
      appUuid: this.$route.params.merchantUuid,
      first: this.limit,
      after: this.startCursor,
    };
  }

  private get headers() {
    const commonProps = {
      align: "start",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4",
    };

    return [
      {
        ...commonProps,
        text: "Billing No",
        value: "referenceNumber",
      },
      {
        ...commonProps,
        text: "Billing Period",
        value: "billingPeriod",
      },
      {
        ...commonProps,
        text: "Amount",
        value: "currentAmount",
      },
      {
        ...commonProps,
        text: "Due Date",
        value: "billingPeriodDueAt",
      },
      {
        ...commonProps,
        text: "Tiering Type",
        value: "plan",
      },
      {
        ...commonProps,
        text: "Status",
        value: "status",
      },
      {
        ...commonProps,
        text: "Deposit Slip",
        value: "latestDepositSlip",
      },
      {
        ...commonProps,
        text: "Action",
        value: "action",
      },
    ];
  }

  private latestDepositSlip(billing: any) {
    const { depositSlips } = billing;

    return depositSlips.length > 0
      ? depositSlips.map(
          ({ originalUrl }: { originalUrl: string }) => originalUrl
        )[0]
      : null;
  }

  private isPaid(status: string) {
    return status.toLowerCase() === "paid";
  }

  private forPayment(status: string) {
    return snakeCase(status.toLowerCase()) === "for_payment";
  }

  private paymentSubmitted(status: string) {
    return snakeCase(status.toLowerCase()) === "payment_submitted";
  }

  private onBillingCreate() {
    this.loadingInProgress();
    const mutation = billingCreate;
    if (this.merchant.serviceBundle.name === BundleTypes.affiliate) {
      this.showErrorAlert(
        "Billing cannot be generated for Affiliate Bundle apps"
      );
      this.loadingDone();
      return;
    }
    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { appUuid: this.$route.params.merchantUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.showSuccessAlert(`Billing generated`);
          this.loadingDone();
          this.$apollo.queries.billings.refresh();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onBillingStatusUpdate() {
    this.loadingInProgress();
    const mutation = this.isBillingApproveEmitter
      ? billingApprove
      : billingReject;
    const message = this.isBillingApproveEmitter ? "Approved" : "Rejected";

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { billingUuid: this.billingUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.showSuccessAlert(`Billing ${message}`);
          this.$apollo.queries.billings.refresh();
          this.loadingDone();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private onDisplayApprovedBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be approved. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-approve";
  }

  private handleDialogCancel() {
    this.billingUuid = null;
    this.billingConfirmationMessage = "";
    this.eventEmitter = "";
  }

  private onDisplayRejectBillingDialog(billingUuid: string) {
    this.billingConfirmationMessage =
      "Payment will be rejected. Do you wish to continue?";
    this.billingUuid = billingUuid;
    this.eventEmitter = "billing-reject";
  }

  private onDisplayGenerateMonthlyBillingDialog() {
    this.billingUuid = `billing-${new Date().toISOString()}`;
    this.billingConfirmationMessage =
      "Will manually generate merchant billing. Do you wish to continue?";
    this.eventEmitter = "monthly-billing-generate";
  }

  private displayTieringType(tierType: string) {
    if (tierType === "pro_tier") {
      return "Pro Tier";
    } else if (tierType === "enterprise") {
      return "Enterprise";
    } else {
      return "Free Tier";
    }
  }
}
