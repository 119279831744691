
import { Component, Mixins, Prop } from "vue-property-decorator";
import { merchantOrderHistory } from "../api/queries/merchants";
import { OrderStatus } from "../@types/Order";
import moment from "moment-mini";
import { currency } from "../utils/helpers/numeric-formatter";
import { Nullable } from "@/@types/BaseTypes";
import { PageInfo } from "@/@types/Page";
import { fullDateTime } from "@/utils/helpers/date-formatter";
import BreakpointMixin from "@/mixins/BreakpointMixin";

@Component({
  apollo: {
    merchantOrderHistory: {
      query: merchantOrderHistory,
      variables() {
        return this.queryVariables;
      },
      update({ merchantOrderHistory }: any) {
        const { pageInfo } = merchantOrderHistory;
        this.$emit("data:total-count", merchantOrderHistory.totalCount);
        this.$emit("data:page-info", pageInfo);
        this.pageInfo = pageInfo;

        return merchantOrderHistory.edges.map(
          ({ node }: { node: OrderStatus }) => ({
            ...node,
            fullName: `${node.order.firstName} ${node.order.lastName}`,
            placedAt: `${fullDateTime(node.placedAt)}`,
            amount: `${currency(node.order.grandTotal)}`,
            displayedConvenienceFee: `${currency(node.order.transactionFee)}`,
          })
        );
      },
    },
  },
})
export default class MerchantOrders extends Mixins(BreakpointMixin) {
  @Prop({ type: Number, default: 0 })
  private convenienceFees!: number;

  @Prop({ type: Number, default: 10 })
  private limit!: number;

  @Prop({ type: String, default: null })
  private startCursor!: Nullable<string>;

  private merchantOrderHistory: OrderStatus[] = [];

  private pageInfo: Nullable<PageInfo> = null;

  private get queryVariables() {
    return {
      appUuid: this.$route.params.merchantUuid,
      first: this.limit,
      after: this.startCursor,
    };
  }

  private get displayedConvenienceFee() {
    return currency(this.convenienceFees);
  }

  private get headers() {
    const commonProps = {
      align: "start",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4 py-6",
    };

    return [
      {
        ...commonProps,
        text: "Date & Time",
        value: "placedAt",
      },
      {
        ...commonProps,
        text: "Order #",
        value: "order.transactionNumber",
      },
      {
        ...commonProps,
        text: "Product",
        value: "order.firstItem.productName",
      },
      {
        ...commonProps,
        text: "Customer Name",
        value: "fullName",
      },
      {
        ...commonProps,
        text: "Amount",
        value: "amount",
      },
      {
        ...commonProps,
        text: "Payment Method",
        value: "order.paymentOption.paymentName",
      },
      {
        ...commonProps,
        text: "Shipping Method",
        value: "order.shippingMethod.name",
      },
      {
        ...commonProps,
        text: "Convenience Fee",
        value: "displayedConvenienceFee",
        align: "end",
      },
    ];
  }

  private humanizedDateGenerator(date: string) {
    return date ? moment(date).format("MMM DD, YYYY hh:mm a") : "-";
  }
}
