import gql from "graphql-tag";

const thirdPartyProvider = gql`
  fragment ThirdPartyApiSetting on ThirdPartyApiSetting {
    uuid
    apiPublicKey
    apiSecretKey
    provider
  }
`;

const paymentOptionBankDetail = gql`
  fragment PaymentOptionBankDetail on PaymentBankDetail {
    uuid
    bankName
    accountName
    accountNumber
  }
`;

const paymentOptionDetail = gql`
  fragment PaymentOptionDetail on PaymentOptionDetail {
    uuid
    content
  }
`;

const paymentOption = gql`
  fragment PaymentOption on PaymentOption {
    uuid
    active
    enable
    paymentName
    paymentType
    thirdPartyProvider
    creditCardProvider
    thirdPartyApiSetting {
      ...ThirdPartyApiSetting
    }
    bankDetails {
      edges {
        node {
          ...PaymentOptionBankDetail
        }
      }
    }
    optionDetails {
      edges {
        node {
          ...PaymentOptionDetail
        }
      }
    }
  }
  ${paymentOptionDetail}
  ${paymentOptionBankDetail}
  ${thirdPartyProvider}
`;

const paymentGateway = gql`
  fragment PaymentGateway on PaymentGateway {
    uuid
    active
    creditCardProvider
    description
    gatewayType
    paymentOptionType
    title
    appPaymentOption(appUuid: $appUuid) {
      ...PaymentOption
    }
    appPaymentProvider(appUuid: $appUuid) {
      ...ThirdPartyApiSetting
    }
  }
  ${paymentOption}
  ${thirdPartyProvider}
`;

export const Payment = {
  fragments: {
    paymentOptionDetail,
    paymentOptionBankDetail,
    paymentOption,
    paymentGateway,
    thirdPartyProvider,
  },
};
