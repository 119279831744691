import gql from "graphql-tag";
import { Plan } from "../fragments/plan";

export const enterprisePlanSubscribe = gql`
  mutation enterprisePlanSubscribe($input: EnterprisePlanPlanSubscribeInput!) {
    enterprisePlanSubscribe(input: $input) {
      enterprisePlan {
        ...EnterprisePlan
      }
    }
  }
  ${Plan.fragments.enterprisePlan}
`;
