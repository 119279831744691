
import { Component, Mixins } from "vue-property-decorator";
import TopNav from "../components/TopNav.vue";
import FormMixin from "../mixins/FormMixin";
import LoaderMixin from "../mixins/LoaderMixin";

import SignInMutation from "../api/mutations/sign-in.gql";
import { required, email } from "vuelidate/lib/validators";
import JWT from "../utils/helpers/jwt";
import { resetStore } from "../plugins/vue-apollo";
import { CAPTCHA_SITE_KEY } from "@/utils/constants";
// import { captchaSignature } from "@/utils/helpers/jwt";
import AuthMixin from "../mixins/AuthMixin";
import AesMixin from "../mixins/AesMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import moment from "moment-mini";

@Component({
  components: { TopNav },
  validations: {
    email: { required, email },
    password: { required },
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    const currentToken = new JWT();

    if (currentToken.isValid) {
      next("/admin");
    } else {
      next();
    }
  },
})
export default class Login extends Mixins(
  FormMixin,
  LoaderMixin,
  NotifierMixin,
  AuthMixin,
  AesMixin
) {
  private email = "";

  private password = "";

  private captchaPayload() {
    const timestamp = moment().unix();
    const captchaKey = CAPTCHA_SITE_KEY;

    return { captchaKey, timestamp };
  }

  private login() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();

      const input = {
        email: this.encrypt(this.email),
        password: this.encrypt(this.password),
        role: "admin",
        // captchaSignature: this.encrypt(captchaSignature(this.captchaPayload())),
      };

      this.$apollo
        .mutate({
          mutation: SignInMutation,
          variables: { input },
        })
        .then(
          ({
            data: {
              signInCmsEmail: { jwt },
            },
          }: any) => {
            // resets graphql Authorization; including jwt stored in localStorage
            resetStore().then(() => {
              const token = new JWT(jwt);
              token.setToken(jwt);

              this.resetClient();
              this.$router.push("/admin");
            });

            this.loadingDone();
          }
        )
        .catch((e) => {
          this.showErrorAlert(e.message);
          this.loadingDone();
        });
    }
  }
}
