import gql from "graphql-tag";
import { Billing } from "../fragments/billing";
import { Connection } from "../fragments/connection";

export const allBillings = gql`
  query allBillings(
    $appUuids: [ID!]
    $monthFilter: Int
    $keywords: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    allBillings(
      appUuids: $appUuids
      monthFilter: $monthFilter
      keywords: $keywords
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          uuid
          amount
          currentAmount
          referenceNumber
          tierType
          billingPeriodStartAt
          billingPeriodEndAt
          billingPeriodDueAt
          status
          app {
            uuid
            plan
            name
            subscribedEnterprisePlan {
              uuid
              name
            }
          }
          depositSlips {
            originalUrl
          }
          details {
            uuid
            planName
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export const billing = gql`
  query billing($billingUuid: ID!) {
    billing(billingUuid: $billingUuid) {
      ...BillingFull
    }
  }
  ${Billing.fragments.billingFull}
`;
