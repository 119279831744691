
import { Nullable } from "@/@types/BaseTypes";
import { PaymentGateway } from "@/@types/Payment";
import {
  thirdPartyApiSettingCreate,
  thirdPartyApiSettingUpdate,
} from "@/api/mutations/merchant";
import FormMixin from "@/mixins/FormMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";

const defaultFieldType = "password";

@Component({
  validations: {
    secretKey: { required },
    pubKey: { required },
  },
})
export default class PaymentOptionFormProvider extends Mixins(
  LoaderMixin,
  FormMixin
) {
  @Prop({ type: String, default: "" })
  private value!: string;

  @Prop({ type: String, default: "" })
  private title!: string;

  @Prop({ type: Object, default: null })
  private paymentMethod!: Nullable<PaymentGateway>;

  private secretKey = "";

  private pubKey = "";

  private secretKeyInputType = defaultFieldType;

  private pubKeyInputType = "text";

  private create() {
    return this.$apollo.mutate({
      mutation: thirdPartyApiSettingCreate,
      variables: {
        input: {
          apiSecretKey: this.secretKey,
          apiPublicKey: this.pubKey,
          provider: this.paymentMethod?.creditCardProvider,
          appUuid: this.$route.params.merchantUuid,
        },
      },
    });
  }

  private update() {
    return this.$apollo.mutate({
      mutation: thirdPartyApiSettingUpdate,
      variables: {
        input: {
          apiPublicKey: this.pubKey,
          apiSecretKey: this.secretKey,
          thirdPartyApiSettingUuid:
            this.paymentMethod?.appPaymentProvider?.uuid,
        },
      },
    });
  }

  private onSubmit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();
      const mutation = !this.paymentMethod?.appPaymentProvider
        ? this.create
        : this.update;

      mutation()
        .then(() => {
          this.loadingDone();
          this.$emit("form:success", "Payment Setting updated.");
          this.$emit("dialog:close");
        })
        .catch((e) => {
          this.loadingDone();
          this.$emit("form:error", e.message);
          this.$emit("dialog:close");
        });
    }
  }

  private onToggleInputField(field: string) {
    const vm = this as any;
    vm[field] = vm[field] === defaultFieldType ? "text" : defaultFieldType;
  }

  private mounted() {
    if (this.paymentMethod?.appPaymentProvider) {
      this.secretKey = this.paymentMethod.appPaymentProvider.apiSecretKey;
      this.pubKey = this.paymentMethod.appPaymentProvider.apiPublicKey;
    }
  }
}
