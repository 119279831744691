
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import LoaderMixin from "@/mixins/LoaderMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import { Nullable } from "@/@types/BaseTypes";
import { billingExport } from "@/api/mutations/billing";
import { ExportType } from "@/@types/enums/admin/Export";
import { adminExport } from "@/api/mutations/export";
import { exportCount } from "@/api/queries/export";
import { DocumentNode } from "graphql";

@Component({
  apollo: {
    dataCount: {
      query() {
        return exportCount;
      },
      variables() {
        return this.exportVariables(
          this.exportType.exportType,
          this.exportType.paymentType
        );
      },
      update({ exportCount }: { exportCount: Number }) {
        return exportCount;
      },
    },
  },
  components: {
    ConfirmExportDialog: () => import("@/components/ConfirmExportDialog.vue"),
  },
})
export default class Base extends Mixins(LoaderMixin, NotifierMixin) {
  @Prop({ type: Document })
  private subscription!: DocumentNode;

  private billingUuid: Nullable<string> = null;

  private dialogStatus: Nullable<string> = null;

  private eventEmitter = "billing-export";

  private clicked = false;

  private billingConfirmationMessage =
    "Billing details will be exported. Do you wish to continue?";

  private exportVariables(exportFile: string, payment: string) {
    return { exportFile, payment };
  }

  private exportType = {
    exportType: ExportType.billingExport,
    payment: null,
  };

  private exportMutation = adminExport;

  private handleExport() {
    if (this.$route.name === "billing-details") {
      this.onDisplayExportBillingDialog();
    } else {
      this.dialogStatus = "confirm-export-dialog";
    }
  }

  private onDisplayExportBillingDialog() {
    this.billingUuid = this.$route.params.billingUuid;
  }

  private get exportTitle() {
    if (this.loader) {
      return "Exporting";
    }

    if (this.$route.name === "billing-details") {
      return "Export";
    }
    return "Export By Date";
  }

  private onBillingExport() {
    this.loadingInProgress();
    const mutation = billingExport;

    this.$apollo
      .mutate({
        mutation,
        variables: {
          input: { billingUuid: this.billingUuid },
        },
        update: () => {
          this.billingUuid = null;
          this.showSuccessAlert(`Billing exported`);
          this.loadingDone();
        },
      })
      .catch((e) => {
        this.billingUuid = null;
        this.showErrorAlert(e.message);
        this.loadingDone();
      });
  }

  private revertStatus() {
    this.billingUuid = null;
  }

  private dataCount: Number = 0;

  private loader = false;

  @Watch("loader")
  private subscribeOnClick() {
    if (!this.loader) {
      return;
    }

    this.$apollo.queries.dataCount.subscribeToMore({
      document: this.subscription,
      variables: {
        exportFile: "billing_export",
        payment: null,
      },
      updateQuery: () => {
        this.loader = false;
        this.showSuccessAlert("File Exported");
        this.$apollo.queries.dataCount.refetch();
        this.$apollo.queries.dataCount.sub.unsubscribe;
      },
    });
  }
}
