import moment from "moment-mini";
import { FULL_DATE_TIME_FORMAT } from "../constants";

export const shortDateOne = (date: string) => {
  return moment(date).format("MMM DD, YYYY");
};

export const fullDate = (date: string) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const fullDateTime = (date: string) => {
  return moment(date).format(FULL_DATE_TIME_FORMAT);
};
