
import { OrderStatuses } from "@/@types/enums/Order";
import { Vue, Component } from "vue-property-decorator";
import PageTabs from "../components/PageTabs.vue";
import { startCase } from "lodash-core";

const INDEX_ROUTE = "orders-index";

@Component({
  components: {
    PageTabs,
  },
})
export default class Orders extends Vue {
  private get currentTab() {
    return this.$route.query.tab;
  }

  private routeReplace(tab = "pending") {
    const tabTitle = this.statuses.find(
      ({ value }: { value: string }) => value === tab
    )?.label;

    this.$router.replace({
      name: INDEX_ROUTE,
      query: { tab, tabTitle: tabTitle || "" },
    });
  }

  private get statuses() {
    return Object.values(OrderStatuses).map((value: OrderStatuses) => ({
      label:
        value === OrderStatuses.forDelivery ? "Confirmed" : startCase(value),
      value,
    }));
  }

  private mounted() {
    const tab = this.$route.query.tab || "pending";

    this.routeReplace(tab as string);
  }
}
