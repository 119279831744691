
// Core
import { Component, Prop, Mixins } from "vue-property-decorator";
// Extends
import NotifierMixin from "@/mixins/NotifierMixin";
// Children
import CopilotNotes from "@/components/GenAI/CopilotNotes.vue";
import PromptCard from "@/components/GenAI/PromptCard.vue";
import PromptContext from "@/components/GenAI/PromptContext.vue";
// Models / Helpers
import { InheritedPromptTypes, PromptName } from "@/@types/enums/Prompt";
// Queries / Mutations
import promptQuery from "@/api/queries/base-prompt.gql";

@Component({
  components: {
    CopilotNotes,
    PromptCard,
    PromptContext,
  },
  apollo: {
    promptQuery: {
      query: promptQuery,
      variables() {
        return {
          promptUuid: this.uuid,
        };
      },
      update({ fetchBasePrompt }) {
        this.prompt = fetchBasePrompt;
      },
      error({ message }) {
        // showErrorAlert doesn't seem to work
        console.log(message);
        this.showErrorAlert(message);
      },
      skip() {
        return !this.uuid;
      },
    },
  },
})
export default class BasePrompt extends Mixins(NotifierMixin) {
  @Prop({ type: String, default: null })
  private uuid!: any;

  private prompt = {};

  private get title() {
    const key = InheritedPromptTypes[this.prompt.type];

    return null || PromptName[key];
  }

  private get formattedAnswer() {
    let completion = "";
    if (this.prompt.jsonCompletion) {
      completion = this.prompt.jsonCompletion.answer;
    } else {
      completion = this.prompt.completion;
    }

    return completion.replace(/\n/g, "<br>");
  }
}
