// <any> to enable reverse lookups
// https://stackoverflow.com/a/62215827
export enum InheritedPromptTypes {
  greeting = <any>"Prompts::Greeting",
  welcomeMessage = <any>"Prompts::WelcomeMessage",
}

export enum ExtendedPromptTypes {
  productDescription = <any>"PromptProductDescription",
  commentAutoreply = <any>"PromptCommentAutoreply",
  storeLaunch = <any>"PromptStoreLaunch",
}

export enum PromptName {
  greeting = "Greeting Message",
  welcomeMessage = "Welcome Message",
  productDescription = "Product Description",
  commentAutoreply = "Comment Auto-Reply",
  storeLaunch = "Store Launch",
}
