
import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment-mini";
import { SHORT_DATE_FORMAT } from "../utils/constants";
import { Nullable } from "../@types/BaseTypes";

@Component
export default class BaseDatePicker extends Vue {
  private menu1 = false;

  private dateFormatted = "";

  private date = null;

  @Prop({ type: String, default: moment().format(SHORT_DATE_FORMAT) })
  private value!: string;

  @Prop({ type: String, default: null })
  private min!: Nullable<string>;

  @Prop({ type: String, default: null })
  private max!: Nullable<string>;

  @Prop({ type: String, required: true })
  private label!: string;

  @Prop({ type: String, default: "Select Date" })
  private placeholder!: string;

  @Prop({ type: Boolean, default: false })
  private outlined!: boolean;

  private setValue(event: any) {
    this.$emit("input", event);
    this.menu1 = false;
  }
}
