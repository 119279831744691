export const locale = {
  graphql: {
    mutation: {
      success: {
        create: "{name} Successfully created!",
        update: "{name} Successfully updated!",
        destroy: "{name} Successfully removed!",
      },
    },
    errors: {
      "500": "Something went wrong please try again later",
    },
  },
  confirmation: {
    remove: "Do you want to remove this {name}?",
  },
  validations: {
    required: {
      field: "{name} is required",
      selection: "Please select {name}",
    },
    invalid: "{name} is invalid",
    unique: "{name} has already been taken",
    type: "{name} has invalid value",
    max: "{name} should not exceed {length} characters",
    min: "{name} should not below {length} characters",
    same_as: "{name} should be the same as {reference}",
    contains_number: "{name} should at least contain 1 number",
    max_file_size: "{name} should be less than {size}",
  },
};
