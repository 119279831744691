import gql from "graphql-tag";

export const appsWithJntAccount = gql`
  query appsWithJntAccount(
    $keywords: String
    $statusFilter: String
    $dateFilterFrom: String
    $dateFilterTo: String
  ) {
    appsWithJntAccount(
      keywords: $keywords
      statusFilter: $statusFilter
      dateFilterFrom: $dateFilterFrom
      dateFilterTo: $dateFilterTo
    ) {
      edges {
        node {
          id
          uuid
          appName: name
          jntAccount {
            customerId
            dateSubmitted
            validationStatus
          }
          createdAt
        }
      }
      totalCount
    }
  }
`;
