import { Nullable } from "@/@types/BaseTypes";
import moment from "moment-mini";
import { FULL_DATE_TIME_FORMAT } from "../constants";

export const fullDateTime = (date: Nullable<string>) => {
  return date ? moment(date).format(FULL_DATE_TIME_FORMAT) : "-";
};

export const shortDateOne = (date: string) => {
  return moment(date).format("MMM DD, YYYY");
};

export const fullDate = (date: string) => {
  return moment(date).format("MMMM DD, YYYY");
};
