import { Vue, Component } from "vue-property-decorator";

@Component
export default class BreakpointMixin extends Vue {
  public get breakpoint() {
    return this.$vuetify.breakpoint;
  }

  public get deviceWidth() {
    return this.breakpoint.width;
  }

  public get deviceHeight() {
    return this.breakpoint.height;
  }
}
