import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ name: "NotifierModule", namespaced: true })
export default class NotifierModule extends VuexModule {
  public toggleAlert = false;

  public alertType = "success";

  public message = "";

  @Mutation
  public onToggleAlert() {
    this.toggleAlert = !this.toggleAlert;
  }

  @Mutation
  public setAlertType(alertType: string) {
    this.alertType = alertType;
  }

  @Mutation
  public setMessage(message: string) {
    this.message = message;
  }
}
