
import { Component, Mixins, Prop } from "vue-property-decorator";
import { SelectOption } from "../@types/FormInput";
import { Nullable } from "../@types/BaseTypes";
import BreakpointMixin from "@/mixins/BreakpointMixin";

@Component
export default class PageTabs extends Mixins(BreakpointMixin) {
  @Prop({ type: Array, default: () => [] })
  private tabs!: SelectOption[];

  @Prop({ type: String, default: null })
  private value!: Nullable<string>;

  private active(tab: SelectOption) {
    return this.value === tab.value;
  }
}
