
import { Nullable } from "@/@types/BaseTypes";
import { OrderImpl } from "@/@types/implementations/OrderImpl";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class OrderDetailsPayment extends Vue {
  @Prop({ type: Object, default: null })
  private orderDetails!: Nullable<OrderImpl>;
}
