import gql from "graphql-tag";
import { SalesPartner } from "../fragments/user";
import { Billing } from "../fragments/billing";

export const salesPartners = gql`
  query salesPartners {
    salesPartners {
      edges {
        node {
          ...SalesPartner
        }
      }
    }
  }
  ${SalesPartner.fragments.salesPartner}
`;

export const salesPartnerBillings = gql`
  query salesPartnerBillings($userUuid: ID!) {
    referralsBillings(userUuid: $userUuid) {
      edges {
        node {
          ...ReferralBilling
        }
      }
    }
  }
  ${Billing.fragments.referralBilling}
`;
