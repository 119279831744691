import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CurrentUser } from "@/@types/Auth";
import { Nullable } from "@/@types/BaseTypes";

@Module({ name: "CurrentUserModule", namespaced: true })
export default class CurrentUserModule extends VuexModule {
  public currentUser: Nullable<CurrentUser> = null;

  @Mutation
  public setCurrentUser(me: CurrentUser) {
    this.currentUser = me;
  }

  @Mutation
  public removeCurrentUser() {
    this.currentUser = null;
  }

  public get me() {
    return this.currentUser;
  }
}
