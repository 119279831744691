
import { Mixins, Component } from "vue-property-decorator";
import BreakpointMixin from "../mixins/BreakpointMixin";

@Component
export default class BreadCrumb extends Mixins(BreakpointMixin) {
  private get arrowIconSize() {
    return this.breakpoint.mdAndUp ? 35 : 20;
  }
}
