
import { Nullable } from "@/@types/BaseTypes";
import { orderStatusUpdate } from "@/api/mutations/order";
import FormMixin from "@/mixins/FormMixin";
import LoaderMixin from "@/mixins/LoaderMixin";
import NotifierMixin from "@/mixins/NotifierMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";

@Component({
  validations: {
    form: {
      cancelMessage: { required },
      cancelType: { required },
    },
  },
})
export default class CancelOrderDialog extends Mixins(
  FormMixin,
  NotifierMixin,
  LoaderMixin
) {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ type: String, default: null })
  private uuid!: Nullable<string>;

  private status = "cancelled";

  private form = {
    cancelType: "cancelled_order",
    cancelMessage: "",
  };

  private get cancellationTypes() {
    return [
      { text: "Rejected Order", value: "rejected_order" },
      { text: "Delivery Failed", value: "delivery_failed" },
      { text: "Cancelled Order", value: "cancelled_order" },
      { text: "Payment Failed", value: "payment_failed" },
    ];
  }

  private onClose() {
    this.$emit("input", false);
    this.$v.$reset();

    this.form = {
      cancelType: "cancelled_order",
      cancelMessage: "",
    };
  }

  private onCancelOrder() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.loadingInProgress();

      this.$apollo
        .mutate({
          mutation: orderStatusUpdate,
          variables: {
            input: {
              ...this.form,
              status: this.status,
              orderStatusUuid: this.uuid,
            },
          },
        })
        .then(() => {
          this.loadingDone();
          this.onClose();
          // For optimization: reuse apollo store here
          this.$router.push("/admin/orders?tab=cancelled&tabTitle=Cancelled");
          window.scroll({ top: 0, left: 0 });
          const el = document.getElementById("order-details");
          if (el) {
            el.scrollTop = 0;
          }

          this.$emit("notify:success", "Order is cancelled");
        })
        .catch((e: any) => {
          this.loadingDone();
          this.onClose();
          this.$emit("notify:error", e.message);
        });
    }
  }
}
