
import { Nullable } from "@/@types/BaseTypes";
import LoaderMixin from "@/mixins/LoaderMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { orderStatusUpdate } from "@/api/mutations/order";

@Component
export default class CompletedOrderDialog extends Mixins(LoaderMixin) {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ type: String, default: null })
  private uuid!: Nullable<string>;

  private get input() {
    return {
      orderStatusUuid: this.uuid,
      status: "completed",
    };
  }

  private onCancel() {
    this.$emit("input", false);
  }

  private onConfirm() {
    this.loadingInProgress();
    this.$apollo
      .mutate({
        mutation: orderStatusUpdate,
        variables: {
          input: this.input,
        },
      })
      .then(() => {
        this.loadingDone();
        this.onCancel();
        // For optimization: reuse apollo store here
        this.$router.push("/admin/orders?tab=completed&tabTitle=Completed");
        window.scroll({ top: 0, left: 0 });
        const el = document.getElementById("order-details");
        if (el) {
          el.scrollTop = 0;
        }

        this.$emit("notify:success", "Order marked as completed");
      })
      .catch((e: any) => {
        this.loadingDone();
        this.onCancel();
        this.$emit("notify:error", e.message);
      });
  }
}
