
import { Nullable } from "@/@types/BaseTypes";
import { OrderModel } from "@/models/OrderModel";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { orderDetails as query } from "../api/queries/orders";
import { node } from "../utils/helpers/graphql";
import { OrderDetails as OrderDetailsFull } from "../@types/Order";
import ItemDetails from "./OrderDetailsItemList.vue";
import Delivery from "./OrderDetailsDelivery.vue";
import Customer from "./OrderDetailsCustomer.vue";
import Payment from "./OrderDetailsPayment.vue";
import NotifierMixin from "@/mixins/NotifierMixin";

@Component({
  apollo: {
    orderDetails: {
      query,
      variables() {
        return { orderUuid: this.orderUuid };
      },
      // marked as any due to some reactive nested objects and __typename
      update({ orderDetails }: any) {
        this.orderDetails = null;

        return this.orderDetailGenerator(orderDetails);
      },
      skip() {
        return !this.orderUuid;
      },
    },
  },
  components: {
    ItemDetails,
    Delivery,
    Customer,
    Payment,
    ConfirmOrderDialog: () => import("./ConfirmOrderDialog.vue"),
    RejectOrderDialog: () => import("./CancelOrderDialog.vue"),
    CompletedOrderDialog: () => import("./CompletedOrderDialog.vue"),
    RefundOrderDialog: () => import("./RefundOrderDialog.vue"),
  },
})
export default class OrderDetails extends Mixins(NotifierMixin) {
  @Prop({ type: String, default: null })
  private orderUuid!: Nullable<string>;

  private orderDetails: Nullable<OrderDetailsFull> = null;

  private dialogStatus: Nullable<string> = null;

  private get orderDetailsModel() {
    return !this.orderDetails
      ? null
      : new OrderModel(this.orderDetails as OrderDetailsFull);
  }

  // TODO: move as enum
  private get updatableStatuses() {
    return ["for_delivery", "pending", "cancelled"].includes(
      this.orderDetails?.orderStatus.status as string
    );
  }

  private get isPaymentStatusSupported() {
    return !this.orderDetails
      ? false
      : /pay_success/.test(this.orderDetails.orderStatus.paymentStatus);
  }

  private get isPaymentMethodSupported() {
    return !this.orderDetails
      ? false
      : /GCash/.test(this.orderDetails.paymentOption.paymentName);
  }

  private get isSalesChannelSupported() {
    return !this.orderDetails
      ? false
      : /messenger|gcash/.test(this.orderDetails.platformOrigin);
  }

  private get isRefundSupported() {
    return (
      this.isSalesChannelSupported &&
      this.isPaymentStatusSupported &&
      this.isPaymentMethodSupported
    );
  }

  private orderDetailGenerator(orderDetails: any) {
    // Base case (checker if items in apollo cache already updated)
    if (orderDetails.items.length) {
      return orderDetails;
    }

    let items = orderDetails.items.edges.map(node).map((item: any) => ({
      ...item,
      itemOptions: item.itemOptions.edges.map(node),
    }));

    Object.assign(orderDetails, { ...orderDetails, items });

    // map pickupPoints
    if (orderDetails.shippingMethod) {
      const pickupPoints =
        orderDetails.shippingMethod.pickupPoints.edges.map(node);
      Object.assign(orderDetails, {
        ...orderDetails,
        shippingMethod: {
          ...orderDetails.shippingMethod,
          pickupPoints,
        },
      });
    }

    return orderDetails;
  }
}
