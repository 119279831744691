import { Nullable } from "@/@types/BaseTypes";
import { PageInfo } from "@/@types/Page";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PaginatorMixin extends Vue {
  public limit = 5;

  public pageInfo: Nullable<PageInfo> = null;

  public totalCount = 0;

  public startCursor: Nullable<string> = null;

  public paginateRecordsFrom(cursor: Nullable<string> = null) {
    this.startCursor = cursor;
    console.log(cursor);

    if (this.pageInfo) {
      this.pageInfo.startCursor = cursor;
    }
    console.log(cursor);
  }

  public setLimit(limit: number) {
    this.limit = limit;
  }
}
