
// Core
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// Extends
// Children
import PromptCard from "@/components/GenAI/PromptCard.vue";
// Models / Helpers
import { InheritedPromptTypes } from "@/@types/enums/Prompt";
// Queries / Mutations
import promptQuery from "@/api/queries/error-prompt.gql";

@Component({
  components: {
    PromptCard,
  },
  apollo: {
    promptQuery: {
      query: promptQuery,
      variables() {
        return {
          promptUuid: this.uuid,
        };
      },
      update({ fetchBasePrompt }) {
        this.prompt = fetchBasePrompt;
      },
      skip() {
        return !this.uuid;
      },
    },
  },
})
export default class ErrorPrompt extends Vue {
  @Prop({ type: String, default: null })
  private uuid!: any;

  private prompt = {};

  private get title() {
    switch (this.prompt.type) {
      case InheritedPromptTypes.greeting:
        return "Greeting Message";
      case InheritedPromptTypes.welcomeMessage:
        return "Welcome Message";
    }
    return "placeholder";
  }
}
