
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseOverlayLoader extends Vue {
  @Prop({ type: String, default: "" })
  private message!: string;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: Boolean, default: false })
  private spinner!: boolean;

  private get loaderTypeComponent() {
    return !this.spinner ? "v-progress-linear" : "v-progress-circular";
  }
}
