export enum PaymentStatuses {
  payPending = "pay_pending",
  paySuccess = "pay_success",
  forReupload = "for_reupload",
  forConfirmation = "for_confirmation",
  refundSuccess = "refund_success",
}

export enum OrderStatuses {
  pending = "pending",
  forDelivery = "for_delivery",
  completed = "completed",
  cancelled = "cancelled",
  refunded = "refunded",
}

export enum SalesChannel {
  instagram = "instagram",
  viber = "viber",
  messenger = "messenger",
  gcash = "gcash",
}

export enum CancelType {
  deliveryFailed = "delivery_failed",
  rejectedOrder = "rejected_order",
  cancelledOrder = "cancelled_order",
  paymentFailed = "payment_failed",
  autoconfirm = "autoconfirm",
}
