
import { Mixins, Component } from "vue-property-decorator";
import { salesPartnerBillings } from "../api/queries/sales-partners";
import { shortDateOne } from "@/utils/helpers/date-formatter";
import { currency } from "@/utils/helpers/numeric-formatter";
import { startCase } from "lodash-core";
import BillingActionMixin from "../mixins/BillingActionMixin";
import ImagePreviewDialog from "../components/ImagePreviewDialog.vue";

@Component({
  apollo: {
    billings: {
      query: salesPartnerBillings,
      variables() {
        return {
          userUuid: this.salesPartnerUuid,
        };
      },
      fetchPolicy: "cache-and-network",
      update({ referralsBillings }: any) {
        return referralsBillings.edges.map(({ node }: any) => {
          return {
            ...node,
            amount: currency(node.currentAmount),
            billingPeriod: `${shortDateOne(
              node.billingPeriodStartAt
            )} - ${shortDateOne(node.billingPeriodEndAt)}`,
            dueDate: shortDateOne(node.billingPeriodDueAt),
            actions: node.uuid,
            status: startCase(node.status),
            latestDepositSlip: this.latestDepositSlip(node),
          };
        });
      },
    },
  },
  components: {
    ImagePreviewDialog,
  },
})
export default class SalesPartnerBillings extends Mixins(BillingActionMixin) {
  private billings = [];

  private get salesPartnerUuid() {
    return this.$route.params.salesPartnerUuid;
  }

  private get headers() {
    const commonProps = {
      align: "center",
      sortable: false,
      class: "font-weight-bold text-uppercase grey lighten-4 py-4",
    };

    return [
      {
        ...commonProps,
        text: "BILLING NO.",
        align: "start",
        value: "referenceNumber",
      },
      {
        ...commonProps,
        text: "APP NAME",
        align: "start",
        value: "app.name",
      },
      {
        ...commonProps,
        text: "BILLING PERIOD",
        align: "start",
        value: "billingPeriod",
      },
      {
        ...commonProps,
        text: "BILLING DUE DATE",
        align: "start",
        value: "dueDate",
      },
      {
        ...commonProps,
        text: "AMOUNT",
        align: "start",
        value: "amount",
      },
      {
        ...commonProps,
        text: "STATUS",
        align: "start",
        value: "status",
      },
      {
        ...commonProps,
        text: "DEPOSIT SLIP",
        align: "start",
        value: "latestDepositSlip",
      },
      /*
       * Temporary Disable Action Buttons
        {
          ...commonProps,
          text: "",
          width: "10%",
          align: "start",
          value: "actions",
        },
      */
    ];
  }
}
