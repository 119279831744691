
import { Component, Prop, Mixins } from "vue-property-decorator";
import AuthMixin from "../mixins/AuthMixin";
import BreakpointMixin from "../mixins/BreakpointMixin";

@Component
export default class TopNav extends Mixins(BreakpointMixin, AuthMixin) {
  @Prop({ type: Boolean, default: false })
  private authenticated!: boolean;

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  private settingsMenu = false;

  private get avatarSize() {
    return this.breakpoint.mdAndUp ? "48" : "40";
  }

  private get topNavHeight() {
    return this.breakpoint.smAndDown ? "64" : "84";
  }
}
