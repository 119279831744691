import { Vue, Component } from "vue-property-decorator";
import CryptoJS from "crypto-js";
import { AES_SECRET_KEY } from "@/utils/constants";

@Component
export default class AesMixin extends Vue {
  public decrypt(token: string) {
    const encodedToken = CryptoJS.enc.Base64.parse(token);
    const base64Token = encodedToken
      .toString(CryptoJS.enc.Utf8)
      .split("\n")
      .join("");
    const received = CryptoJS.enc.Base64.parse(base64Token);
    const salt = CryptoJS.enc.Hex.parse(received.toString().slice(0, 32));
    const iv = CryptoJS.enc.Hex.parse(received.toString().slice(32, 64));
    const cipher = CryptoJS.enc.Hex.parse(received.toString().slice(64));
    const key = CryptoJS.PBKDF2(AES_SECRET_KEY, salt, {
      keySize: 256 / 32,
      iterations: 100,
    });

    const decodedCipher = CryptoJS.AES.decrypt(
      cipher.toString(CryptoJS.enc.Base64),
      key,
      {
        iv,
      }
    ).toString(CryptoJS.enc.Utf8);

    return decodedCipher;
  }

  public encrypt(payload: string) {
    if (process.env.NODE_ENV === "development") {
      return payload;
    }

    const iv = CryptoJS.lib.WordArray.random(16);
    const salt = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.PBKDF2(AES_SECRET_KEY, salt, {
      keySize: 256 / 32,
      iterations: 100,
    });
    const cipher = CryptoJS.AES.encrypt(payload, key, {
      iv,
    });
    const cipheredToken = CryptoJS.enc.Hex.parse(
      salt.toString() + iv.toString() + cipher.ciphertext.toString()
    ).toString(CryptoJS.enc.Base64);

    const decodeCiphered = CryptoJS.enc.Utf8.parse(cipheredToken);

    const base64Token = CryptoJS.enc.Base64.stringify(decodeCiphered);

    return base64Token;
  }
}
