
import { Nullable } from "@/@types/BaseTypes";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MerchantTopUp extends Vue {
  @Prop({ type: String, default: "" })
  private direction!: string;

  @Prop({ type: String, default: null })
  private startCursor!: Nullable<string>;

  @Prop({ type: String, default: 10 })
  private limit!: string;
}
