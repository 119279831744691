import Vue from "vue";
import { ApolloClient } from "apollo-client";
import VueApollo from "vue-apollo";
import { ApolloLink, concat } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import ActionCable from "actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from "apollo-upload-client";
import { AUTH_TOKEN_KEY, HTTP_ENDPOINT, WS_ENDPOINT } from "@/utils/constants";
import { jwtDefaultBearerGenerator } from "@/utils/helpers/jwt";

Vue.use(VueApollo);

let defaultClient: ApolloClient<{}>;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: { types: [] }, // no types provided
  },
});
const cable = ActionCable.createConsumer(WS_ENDPOINT);
const httpLink: any = createUploadLink({ uri: HTTP_ENDPOINT });
const storedToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
const wsLink = new ActionCableLink({
  cable,
  connectionParams: { token: jwtDefaultBearerGenerator(storedToken) },
}) as any;

const link = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const middleware = (token: string | null) => {
  const authorization = jwtDefaultBearerGenerator(token);
  return new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: { authorization },
    });

    if (forward) {
      return forward(operation);
    }

    return null;
  });
};

const client = (token: string | null) => {
  const authMiddleware = middleware(token);

  defaultClient = new ApolloClient({
    link: concat(authMiddleware, link),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object: any) => object.uuid || null,
    }),
  });

  return defaultClient;
};

export function createProvider(token: string | null = storedToken) {
  const defaultClient = client(token);
  return new VueApollo({ defaultClient });
}

export async function resetStore() {
  try {
    await defaultClient.resetStore();
  } catch (e) {
    /* eslint-disable */
    // tslint:disable-next-line
    console.log('%cError on cache reset (${event})', 'color: orange;', e.message);
    /* eslint-enable */
  }
}
