import gql from "graphql-tag";

const disbursementFull = gql`
  fragment DisbursementFull on Disbursement {
    bankAccountName
    bankBranch
    bankName
    bankNumber
    birFilename
    birUrl
    businessAddress
    businessName
    businessNature
    businessPermitFilename
    businessPermitUrl
    businessType
    companyProfileFilename
    companyProfileUrl
    createdAt
    dtiFilename
    dtiUrl
    email
    establishedDate
    governmentIdFilename
    governmentIdUrl
    mobileNumber
    phoneNumber
    postalCode
    status
    tin
    updatedAt
    uuid
    vatable
    signatories { 
      fullName
      mobileNumber
      position
    }
  }
`;

export const Disbursement = {
  fragments: {
    disbursementFull,
  },
};
