import { gql } from "graphql-tag";
import { Shipping } from "../fragments/shipping";
import { Payment } from "../fragments/payment";
import { Merchant } from "../fragments/merchant";

export const shippingMethodPublishUpdate = gql`
  mutation shippingMethodPublishUpdate($input: ShippingMethodPublishInput!) {
    shippingMethodPublishUpdate(input: $input) {
      shippingMethod {
        ...ShippingMethodBasic
      }
    }
  }
  ${Shipping.fragments.shippingBasic}
`;

export const paymentOptionUpdateStatus = gql`
  mutation paymentOptionUpdateStatus($input: PaymentOptionUpdateStatusInput!) {
    paymentOptionUpdateStatus(input: $input) {
      option {
        ...PaymentOption
      }
    }
  }
  ${Payment.fragments.paymentOption}
`;

export const updateMerchantAddressable = gql`
  mutation updateAppAddressable($input: UpdateAppAddressableInput!) {
    updateAppAddressable(input: $input) {
      app {
        ...MerchantFull
      }
    }
  }
  ${Merchant.fragments.merchantFull}
`;

export const paymentOptionCreate = gql`
  mutation paymentOptionCreate($input: PaymentOptionCreateInput!) {
    paymentOptionCreate(input: $input) {
      option {
        ...PaymentOption
      }
    }
  }
  ${Payment.fragments.paymentOption}
`;

export const paymentOptionUpdate = gql`
  mutation paymentOptionUpdate($input: PaymentOptionUpdateInput!) {
    paymentOptionUpdate(input: $input) {
      option {
        ...PaymentOption
      }
    }
  }
  ${Payment.fragments.paymentOption}
`;

export const thirdPartyApiSettingCreate = gql`
  mutation thirdPartyApiSettingCreate(
    $input: thirdPartyApiSettingCreateInput!
  ) {
    thirdPartyApiSettingCreate(input: $input) {
      thirdPartyApiSetting {
        ...ThirdPartyApiSetting
      }
    }
  }
  ${Payment.fragments.thirdPartyProvider}
`;

export const thirdPartyApiSettingUpdate = gql`
  mutation thirdPartyApiSettingUpdate(
    $input: thirdPartyApiSettingUpdateInput!
  ) {
    thirdPartyApiSettingUpdate(input: $input) {
      thirdPartyApiSetting {
        ...ThirdPartyApiSetting
      }
    }
  }
  ${Payment.fragments.thirdPartyProvider}
`;

export const thirdPartyApiSettingOptionUpdate = gql`
  mutation thirdPartyApiSettingUpdate(
    $input: thirdPartyApiSettingOptionUpdateInput!
  ) {
    thirdPartyApiSettingOptionUpdate(input: $input) {
      option {
        ...PaymentOption
      }
    }
  }
  ${Payment.fragments.paymentOption}
`;

export const updateWeeklyOrderSummaryNotification = gql`
  mutation ($input: UpdateWeeklyOrderSummaryNotificationInput!) {
    updateWeeklyOrderSummaryNotification(input: $input) {
      app {
        weeklyOrderSummaryNotification
      }
    }
  }
`;
