import { gql } from "graphql-tag";
import { Billing } from "../fragments/billing";

export const gracePeriodActivate = gql`
  mutation gracePeriodActivate($input: BillingGracePeriodActivationInput!) {
    gracePeriodActivate(input: $input) {
      billing {
        ...BillingBasic
      }
    }
  }
  ${Billing.fragments.billingBasic}
`;
