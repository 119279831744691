import gql from "graphql-tag";

export const jntAccountUpdate = gql`
  mutation jntAccountUpdate($input: JntAccountUpdateInput!) {
    jntAccountUpdate(input: $input) {
      jntAccount {
        id
        customerId
        dateSubmitted
        validationStatus
      }
    }
  }
`;
